import React from "react";
import { coreNotificationToast } from "../../COREDesignSystem/Feedback/CORENotificationToast";
import { COREButton } from "../../COREDesignSystem/Action/COREButton";
import { Col, notification, Row, Space } from "antd";
import {
  dateFormatWithTimeZone,
  displayRelativeDate,
} from "../../shared/date/DateTime";
import moment from "moment";
import { COREBody } from "../../COREDesignSystem/Typography/COREBody";
import { COREHeading } from "../../COREDesignSystem/Typography/COREHeading";
import { Notifications } from "../../openapi-typescript/common/Notifications";
import { keysToSnake } from "../../shared/global";
import { coreMessage } from "../../COREDesignSystem/Feedback/COREMessage";
import { QueryClient, QueryClientProvider, useMutation } from "react-query";
import { AxiosResponse } from "axios";
import { History } from "history";

const MarkReadButton: React.FC<{
  notificationKey: string;
  id?: Notifications["id"];
  appQueryClient: QueryClient;
}> = ({ notificationKey, id, appQueryClient }) => {
  const { mutate: onMarkRead } = useMutation<AxiosResponse<unknown>>(
    () => {
      return appQueryClient.fetchQuery({
        queryKey: [
          "updateNotification",
          {
            params: { id: `eq.${id}` },
            action: "updateNotification",
            body: JSON.stringify(
              keysToSnake({
                readAt: moment().format(dateFormatWithTimeZone),
                deliveryStatus: "read",
              })
            ),
            enabled: true,
          },
        ],
      });
    },
    {
      onSuccess: () => {
        appQueryClient.invalidateQueries("getNotification");
        notification.close(notificationKey);
        return coreMessage({
          type: "success",
          content: "Successfully mark read",
        });
      },
      onError: () => {
        return coreMessage({
          type: "error",
          content: "Failed to mark read",
        });
      },
    }
  );

  return (
    <COREButton
      size="sm"
      type="default"
      onClick={() => {
        onMarkRead();
      }}
      testID="notification-list-notification-toast-mark-read-button"
    >
      Mark read
    </COREButton>
  );
};

const ViewNowButton: React.FC<{
  notificationItem: Notifications;
  history: History;
}> = ({ notificationItem: { payload }, history }) => (
  <COREButton
    size="sm"
    type="default"
    testID="notification-list-notification-toast-view-now-button"
    onClick={() => history.push(payload.url ?? "/notifications")}
  >
    View now
  </COREButton>
);

export const displayNotificationToast = ({
  payload: notificationItem,
  queryClient: appQueryClient,
  history,
}: {
  payload?: Notifications;
  queryClient: QueryClient;
  history: History;
}) => {
  if (!notificationItem) return;

  const notificationKey = `notification-toast-${notificationItem?.id}`;

  coreNotificationToast({
    key: notificationKey,
    titleMessage: (
      <COREHeading
        level={5}
        testID="notification-list-notification-toast-header"
        marginBottom={false}
      >
        {notificationItem?.payload.title ?? "Title"}
      </COREHeading>
    ),
    descriptionMessage: (
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <COREBody
            type="p3"
            marginBottom={false}
            testID="notification-list-notification-toast-description"
          >
            {notificationItem?.payload.body ?? "description"}
          </COREBody>
        </Col>
        <Col span={24}>
          <COREBody
            type="p5"
            marginBottom={false}
            testID="notification-list-notification-toast-relative-date"
          >
            {displayRelativeDate(moment(notificationItem?.createdAt))}
          </COREBody>
        </Col>

        <Col span={24}>
          <Space size={8}>
            <ViewNowButton
              notificationItem={notificationItem}
              history={history}
            />
            <QueryClientProvider client={appQueryClient}>
              <MarkReadButton
                notificationKey={notificationKey}
                id={notificationItem?.id}
                appQueryClient={appQueryClient}
              />
            </QueryClientProvider>
          </Space>
        </Col>
      </Row>
    ),
    testID: "notification-list-notification-toast",
  });
};
