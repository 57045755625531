import React from "react";
import { purple100 } from "../../COREDesignSystem/Content/COREColour";
import { Badge } from "antd";
import { useTotalNotification } from "../../shared/customHoooks/useNotifications";
import { COREError } from "../../COREDesignSystem/Content/COREError";
import { CORELoading } from "../../COREDesignSystem/Feedback/CORELoading";

export const NotificationBadgeCount: React.FC<{
  customShowZero?: React.ReactNode;
}> = ({ customShowZero }) => {
  const {
    loading,
    sync,
    error,
    total: totalNotification,
  } = useTotalNotification();

  if (loading && !sync) return <CORELoading message="" />;
  if (error) return <COREError />;

  if (customShowZero && totalNotification === 0) {
    return <>{customShowZero}</>;
  }

  return (
    <Badge count={totalNotification} style={{ backgroundColor: purple100 }} />
  );
};
