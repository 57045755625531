import {
  getContentRange,
  UpdatableItemSync,
  UpdatablesHook,
} from "../../shared/customHoooks/useUpdatable";
import { COREError } from "./COREError";
import React, { ReactNode } from "react";
import { CORETable, CORETableProps } from "./CORETable";
import {
  LocalStorageUntilLogoutKey,
  useLocalStorageUntilLogout,
} from "../../shared/customHoooks/useLocalStorageUntilLogout";
import {
  COREPagination,
  COREPaginationProps,
} from "../Navigation/COREPagination";
import { MRT_SortingState as MRTSortingState } from "material-react-table";
import { useLocalStorage } from "react-use";
import { SortingState } from "@tanstack/table-core";

type TableOptions = {
  pagination: Required<
    Pick<
      COREPaginationProps,
      "total" | "current" | "hideOnSinglePage" | "pageSize"
    >
  >;
};

export const initPagination = (initTablePageSize: number) => ({
  total: Number.MAX_SAFE_INTEGER,
  hideOnSinglePage: false,
  current: 1,
  pageSize: initTablePageSize,
});

const initTableOptions = (initTablePageSize: number) => ({
  pagination: initPagination(initTablePageSize),
  filters: {},
});

const convertToOrderFormat = (array?: MRTSortingState) => {
  if (array === undefined || array.length === 0) return {};
  const { id, desc } = array[0];
  return { order: `${id}.${desc ? "desc" : "asc"}.nullslast` };
};
const defaultPageSize: number = 20;

export type CORETableHookParams<
  Params,
  RecordType extends Record<string, unknown>,
  Response,
  AddRequest = Response,
  AddResponse = Response,
  UpdateRequest = Response,
  UpdateResponse = Response,
  DeleteResponse = Response
> = {
  useUpdatables: (
    args: Params,
    tableOption?: TableOptions
  ) => UpdatablesHook<
    Response,
    AddRequest,
    AddResponse,
    UpdateRequest,
    UpdateResponse,
    DeleteResponse
  >;
  params: Params;
  transform: (
    record: UpdatableItemSync<
      Response,
      UpdateRequest,
      UpdateResponse,
      DeleteResponse
    >
  ) => RecordType;
} & Omit<
  CORETableProps<RecordType>,
  "data" | "enableGrouping" | "enablePagination"
> &
  (
    | { enableGrouping: true }
    | (({ enableGrouping: false } | { enableGrouping: undefined }) &
        (
          | { enableDownloadCSV: false; customDownloadCSVButton?: never }
          | { enableDownloadCSV: true; customDownloadCSVButton: ReactNode }
        ))
  );

export const CORETableHook = <
  Params,
  RecordType extends Record<string, unknown>,
  Response,
  AddRequest = Response,
  AddResponse = Response,
  UpdateRequest = Response,
  UpdateResponse = Response,
  DeleteResponse = Response
>({
  useUpdatables,
  params,
  transform,
  testID,
  customDownloadCSVButton,
  ...props
}: CORETableHookParams<
  Params,
  RecordType,
  Response,
  AddRequest,
  AddResponse,
  UpdateRequest,
  UpdateResponse,
  DeleteResponse
>) => {
  const [tableOptions, setTableOptions] =
    useLocalStorageUntilLogout<TableOptions>({
      key: props.localStorageKey as LocalStorageUntilLogoutKey,
      initialValue: initTableOptions(defaultPageSize),
    });

  const [sorting, setSorting] = useLocalStorage<SortingState>(
    `${props.localStorageKey}-sorting`,
    []
  );
  const { pagination } =
    tableOptions && Object.keys(tableOptions).length !== 0
      ? tableOptions
      : initTableOptions(defaultPageSize);

  const { current, pageSize } = pagination;

  const handleSortingChange = (
    update: SortingState | ((prev: SortingState) => SortingState)
  ) => {
    setSorting((prev) =>
      typeof update === "function" ? update(prev ?? []) : update ?? []
    );
  };

  const {
    sync,
    loading,
    error,
    headers,
    updatable: updatables,
  } = useUpdatables(
    { ...params, ...convertToOrderFormat(sorting) },
    !props.enableGrouping ? { pagination: pagination } : undefined
  );

  if (error) {
    if (error.message.includes("416")) {
      setTableOptions((curTableOption?: TableOptions): TableOptions => {
        if (!curTableOption) {
          return { pagination: initPagination(defaultPageSize) };
        }
        const {
          pagination: { current, ...others },
        } = curTableOption;
        return {
          pagination: {
            ...others,
            current: 1,
          },
        };
      });
    }
    console.error(error);
    return <COREError title={"Error loading table data"} />;
  }

  let total = 0;
  if (headers) {
    const contentRange = getContentRange(headers);
    if (contentRange) {
      const totalRecords: string = contentRange.split("/")[1];
      total = totalRecords === "*" ? total : parseInt(totalRecords);
    }
  }
  const dataSource =
    sync && transform
      ? updatables.flatMap((u) => {
          if (u.sync) return [transform(u)];
          return [];
        })
      : [];

  return (
    <CORETable
      {...props}
      testID={`${testID}-table`}
      isLoading={loading && !sync}
      data={dataSource}
      {...(!props.enableGrouping && {
        enablePagination: false,
        customDownloadCSVButton: customDownloadCSVButton,
        defaultPageSize: pageSize,
        state: { sorting: sorting },
        manualSorting: true,
        onSortingChange: handleSortingChange,
        customPagination: (
          <COREPagination
            testID={`${testID}-pagination`}
            total={total}
            current={current}
            showSizeChanger
            pageSize={pageSize}
            onChange={(newPage, newPageSize) => {
              setTableOptions((curTableOption: TableOptions): TableOptions => {
                if (!curTableOption) {
                  return { pagination: initPagination(defaultPageSize) };
                }
                const {
                  pagination: { current, pageSize, ...others },
                } = curTableOption;
                return {
                  pagination: {
                    ...others,
                    current: newPage,
                    pageSize: newPageSize,
                  },
                };
              });
            }}
          />
        ),
      })}
    />
  );
};
