import React from "react";
import { Form } from "antd";
import { generateTestId } from "../../../../shared/testids/testids";
import { COREModal } from "../../../../COREDesignSystem/Overlay/COREModal";
import { COREButton } from "../../../../COREDesignSystem/Action/COREButton";
import { COREIcon } from "../../../../COREDesignSystem/Content/COREIcon";
import { grey100 } from "../../../../COREDesignSystem/Content/COREColour";
import { COREForm, Store } from "../../../../COREDesignSystem/Form/COREForm";
import {
  DashboardNameFormItem,
  DashboardTemplateFormItem,
  MarkAsHomepageFormItem,
} from "./AddEditDashboardFormItems";
import { COREDivider } from "../../../../COREDesignSystem/Layout/COREDivider";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  Dashboard,
  DashboardOutput,
  UseUserDashboardOutput,
} from "../../../../shared/customHoooks/useUserDashboard";
import { useHistory } from "react-router-dom";
import { appQueryClient } from "../../../../shared/state/appQueryClient";
import { uniqueName } from "../../dashboardHelper";
import { normalizeLayout } from "../../../../shared/customHoooks/useDashboardTemplate";

type DashboardTabModalParams = {
  modalVisible?: boolean;
  closeModal: () => void;
  userDashboards?: Dashboard[] | DashboardOutput[];
} & Pick<UseUserDashboardOutput, "addDashboard">;

const defaultLayout = { lg: [], md: [], sm: [], xs: [], xxs: [] };

export const DashboardConfigurationModal: React.FC<DashboardTabModalParams> = ({
  modalVisible,
  addDashboard,
  closeModal,
  userDashboards,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const onCancel = () => {
    form.resetFields();
    closeModal();
  };
  const onFinish = async (values: Store) => {
    const layouts =
      values.templates !== "blank"
        ? normalizeLayout(values.templates)
        : defaultLayout;
    const res = await addDashboard(values.name, layouts, values.markAsHomepage);
    if (res) {
      await appQueryClient.invalidateQueries("user_configs");
      await appQueryClient.invalidateQueries("userAccessibleSections");
      history.push(`/dashboard/${values.name}`);
    }
    onCancel();
  };

  return (
    <COREModal
      width="576px"
      type="basic"
      testID={generateTestId("dashboard", "configuration-modal")}
      visible={modalVisible}
      title="Dashboard configuration"
      onCancel={onCancel}
      icon={<COREIcon icon={icon({ name: "gauge-high" })} color={grey100} />}
      footer={[
        <COREButton
          type="primary"
          size="lg"
          onClick={form.submit}
          icon={
            <COREIcon icon={icon({ name: "check-circle", style: "regular" })} />
          }
          testID={generateTestId("dashboard", "confirm-delete-button")}
          key="confirm-delete-button"
        >
          Save
        </COREButton>,
        <COREButton
          onClick={onCancel}
          type="default"
          size="lg"
          testID={generateTestId("dashboard", "cancel-delete-button")}
          key="cancel-delete-button"
        >
          Cancel
        </COREButton>,
      ]}
      destroyOnClose
    >
      <COREForm
        initialValues={{ templates: "blank" }}
        onFinish={onFinish}
        form={form}
        testID={generateTestId("dashboard", "edit-dashboard-form")}
        layout="none"
      >
        <DashboardTemplateFormItem />
        <DashboardNameFormItem
          rules={[
            {
              required: true,
              validator: (_, value) => uniqueName(value, userDashboards),
            },
          ]}
        />
        <COREDivider dashed />
        <MarkAsHomepageFormItem />
      </COREForm>
    </COREModal>
  );
};
