import { initPagination } from "../../COREDesignSystem/Content/CORETableHook";
import { Notifications } from "../../openapi-typescript/common/Notifications";
import { buildQuerySelect, keysToCamelCase, keysToSnake } from "../global";
import { useUserID } from "../state/user";
import { useGetMenuPermission } from "./useFeatureFlags";
import { getTotalRecords, TableOptions, useUpdatables } from "./useUpdatable";
import { useMutation } from "react-query";
import { appQueryClient, mutation } from "../state/appQueryClient";
import { coreMessage } from "../../COREDesignSystem/Feedback/COREMessage";

export type NotificationParams = {
  id?: number;
  status?: "read" | "unread";
  skip?: boolean;
};

export const useNotifications = (
  args?: NotificationParams,
  tableOption?: TableOptions
) => {
  const { isHavePermission: hasNotificationPermission } = useGetMenuPermission([
    "notifications",
  ]);
  const userId = useUserID();
  const isSkip = args?.skip ?? !hasNotificationPermission;

  return useUpdatables<Notifications>(
    {
      get: [
        "getNotification",
        keysToSnake({
          select: buildQuerySelect({
            id: "id",
            sourceEvent: "source_event",
            payload: "payload",
            readAt: "read_at",
            deliveryMethod: "delivery_method",
            deliveryStatus: "delivery_status",
            createdAt: "created_at",
            modifiedAt: "modified_at",
            event: "events(eventId:id,eventType:event_type)",
          }),
          "event.eventType":
            "not.in.(TaskAssignment.New,TaskAssignment.Removed)",
          event: `not.is.null`,
          deliveryMethod: "eq.inapp",
          ...(args?.status === "unread" && {
            readAt: "is.null",
            deliveryStatus: "eq.sent",
          }),
          ...(args?.status === "read" && {
            readAt: "not.is.null",
            deliveryStatus: "eq.read",
          }),
          order: "created_at.desc",
          ...(args?.id
            ? {
                sourceEvent: `eq.${args.id}`,
              }
            : {
                user: `eq.${userId}`,
              }),
        }),
        isSkip,
      ],
      update: (readNotifications) => ({
        queryKey: [
          "updateNotification",
          {
            action: "updateNotification",
            body: JSON.stringify(
              keysToSnake({
                deliveryStatus: readNotifications.deliveryStatus,
                readAt: readNotifications.readAt,
              })
            ),
            params: { id: `eq.${readNotifications.id}` },
            enabled: true,
          },
        ],
      }),
      transformToRichTypes: (record) => keysToCamelCase(record),
      invalidateQueries: ["getNotification"],
    },
    tableOption
  );
};

export const useTotalNotification = (args?: NotificationParams) => {
  const { headers, ...rest } = useNotifications(
    {
      status: "unread",
      ...args,
    },
    {
      pagination: initPagination(20),
    }
  );

  return {
    ...rest,
    total: getTotalRecords(headers),
  };
};

export const useGetNotificationsIds = (
  args?: NotificationParams,
  tableOption?: TableOptions
) => {
  const { isHavePermission: hasNotificationPermission } = useGetMenuPermission([
    "notifications",
  ]);
  const isSkip = args?.skip ?? !hasNotificationPermission;
  const userId = useUserID();
  return useUpdatables<Notifications>(
    {
      get: [
        "getNotification",
        keysToSnake({
          select: buildQuerySelect({
            id: "id",
          }),
          deliveryMethod: "eq.inapp",
          ...(args?.status === "unread" && {
            readAt: "is.null",
            deliveryStatus: "eq.sent",
          }),
          ...(args?.status === "read" && {
            readAt: "not.is.null",
            deliveryStatus: "eq.read",
          }),
          order: "created_at.desc",
          user: `eq.${userId}`,
        }),
        isSkip,
      ],
      transformToRichTypes: (record) => keysToCamelCase(record),
    },
    tableOption
  );
};

export const useMaskReadAllNotification = () => {
  return useMutation(
    (value: {
      ids?: Notifications["id"][];
      deliveryStatus: Notifications["deliveryStatus"];
      readAt: Notifications["readAt"];
    }) => {
      return mutation({
        queryKey: [
          "updateNotification",
          {
            action: "updateNotification",
            fetchOptions: { method: "PATCH" },
            params: { id: `in.(${value.ids?.join(",")})` },
            body: JSON.stringify(
              keysToSnake({
                deliveryStatus: value.deliveryStatus,
                readAt: value.readAt,
              })
            ),
            enabled: true,
          },
        ],
      });
    },
    {
      onSuccess: () => {
        appQueryClient.invalidateQueries("getNotification");
      },
      onError: (): void => {
        coreMessage({
          type: "error",
          content: `Fail update notification`,
        });
      },
    }
  );
};
