import React from "react";
import { useUserLoggedInStatus, useUserRole } from "../../../shared/state/user";

import {
  matchPath,
  Redirect,
  Route,
  RouteProps,
  useLocation,
} from "react-router-dom";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { PageLayout } from "./PageLayout";
import { Empty } from "antd";
import {
  CURRENT_VERSION,
  fetchVersionFromIndexHtml,
  isVersionValid,
  useCheckFEVersionHasChanged,
} from "../../../shared/useCheckFEVersionHasChanged";
import { usePageChange } from "../../../shared/usePageChange";
import { useUserAccessibleSections } from "../../../shared/customHoooks/useUserAccessibleSections";
import { useUserDashboard } from "../../../shared/customHoooks/useUserDashboard";
import {
  FeatureFlag,
  useGetMenuPermission,
} from "../../../shared/customHoooks/useFeatureFlags";
import { routesWithParams } from "../../../COREDesignSystem/Layout/COREPageHeader";
import { UserAccessibleSection } from "../../../openapi-typescript/common/user_accessible_section";
import { DEFAULT_HOMEPAGE_URL } from "../../../shared/global";

export type DashboardLayoutRouteProps = Pick<
  RouteProps,
  "exact" | "component"
> & {
  path: Exclude<RouteProps["path"], undefined | readonly string[]>;
};

const noPermissionMessage = "No permission to view this page.";
const redirectToLogin = (pathname: string) => {
  const to =
    Boolean(pathname) && pathname !== "/"
      ? `/login?next=${pathname}`
      : "/login";
  return <Redirect to={to} />;
};

export const useCheckUserAccess = (
  path?: DashboardLayoutRouteProps["path"] | null
) => {
  const pageListForCheckFeatureFlag: {
    page: DashboardLayoutRouteProps["path"];
    featureFlag: FeatureFlag;
  }[] = [
    {
      page: "/todo",
      featureFlag: "todo-list",
    },
  ];
  const currentPage = pageListForCheckFeatureFlag.find((p) => p.page === path);
  const {
    loading: loadingUserAccessibleSections,
    sync: syncUserAccessibleSections,
    error: errorUserAccessibleSections,
    userAccessibleSections,
  } = useUserAccessibleSections();
  const {
    loading: loadingMenuPermission,
    sync: syncMenuPermission,
    error: errorMenuPermission,
    isHavePermission: hasPermissionByFeatureFlag,
  } = useGetMenuPermission(currentPage ? [currentPage.featureFlag] : []);

  const ignorePages = [
    "/website-terms-of-use",
    "/privacy-policy",
    "/terms-and-conditions",
    "/help",
  ];

  const locationPathName = window.location.pathname;

  const hasPermissionByUserAccessibleSection = (
    sections: UserAccessibleSection[]
  ): boolean => {
    return sections.some((section) => {
      const { callToActionUrl, children } = section;
      const isDirectMatch =
        path === callToActionUrl || locationPathName === callToActionUrl;
      const isSubPathMatch =
        locationPathName.startsWith(`${callToActionUrl}/`) && !children?.length;

      const routewithParams =
        path &&
        routesWithParams.find((route) =>
          matchPath(path, { path: route, exact: true })
        );

      const hasChildPermission = children?.length
        ? hasPermissionByUserAccessibleSection(children)
        : false;

      return (
        isDirectMatch || isSubPathMatch || routewithParams || hasChildPermission
      );
    });
  };

  return {
    loading: loadingUserAccessibleSections || loadingMenuPermission,
    sync: syncUserAccessibleSections && syncMenuPermission,
    error: errorUserAccessibleSections || errorMenuPermission,
    hasPermission: path
      ? hasPermissionByUserAccessibleSection(userAccessibleSections) ||
        hasPermissionByFeatureFlag ||
        ignorePages.includes(path) ||
        path?.includes("/dashboard")
      : false,
  };
};

export const DashboardLayoutRoute: React.FC<DashboardLayoutRouteProps> = ({
  component,
  path,
  exact,
}) => {
  const location = useLocation();
  const isPendingRole = useUserRole() === "pending";
  const isLoggedIn = useUserLoggedInStatus();
  const {
    sync: syncDashboard,
    loading: loadingDashboard,
    error: errorDashboard,
    markAsHomepage,
    configs: { homepage },
  } = useUserDashboard();
  const hasETAGChanged = useCheckFEVersionHasChanged();
  const { loading, sync, error, hasPermission } = useCheckUserAccess(path);

  usePageChange(async () => {
    if (hasETAGChanged) {
      window.location.reload();
      return;
    }
    const version = await fetchVersionFromIndexHtml();
    if (
      version !== CURRENT_VERSION &&
      isVersionValid(CURRENT_VERSION) &&
      isVersionValid(version)
    ) {
      // eslint-disable-next-line no-alert
      // alert("usePageChange2");
      window.location.reload();
    }
  });
  const { pathname } = location;

  if (!isLoggedIn) {
    return redirectToLogin(pathname);
  }

  if (isPendingRole) {
    return (
      <PageLayout>
        <COREError
          title={
            "No permission to view this page. This account is still pending."
          }
        />
      </PageLayout>
    );
  }

  switch (true) {
    case (loading && !sync && !error) ||
      (loadingDashboard && !syncDashboard && !errorDashboard):
      return <CORELoading size={"lg"} />;
    case !!error || !!errorDashboard:
      return (
        <COREError
          title={`Error loading feature list. ${
            error &&
            typeof error === "object" &&
            error.hasOwnProperty("message")
              ? error.message
              : ""
          }`}
        />
      );
    case !sync || !syncDashboard:
      return (
        <PageLayout>
          <Empty />
        </PageLayout>
      );
    case hasPermission:
      return <Route path={path} component={component} exact={exact} />;
    default: {
      return (
        <PageLayout>
          <COREError
            title={noPermissionMessage}
            path={pathname === homepage ? DEFAULT_HOMEPAGE_URL : "/"}
            onClick={() => {
              if (pathname === homepage) {
                markAsHomepage(DEFAULT_HOMEPAGE_URL);
              }
            }}
          />
        </PageLayout>
      );
    }
  }
};
