import { UserAccessibleSection } from "../../openapi-typescript/common/user_accessible_section";
import { keysToCamelCase } from "../global";
import { useUserRole } from "../state/user";
import { useUpdatables } from "./useUpdatable";
import { BreadcrumbItem } from "../../COREDesignSystem/Navigation/COREBreadcrumb";

export type UserAccessibleSectionWithBreadcrumbs = UserAccessibleSection & {
  breadcrumbs: BreadcrumbItem[];
};

// Searches for a section with the given path and returns it with breadcrumbs, checking child sections if not found at the top level.
export const findByPath = (
  userAccessibleSections: UserAccessibleSection[],
  path: string,
  breadcrumbsParent: BreadcrumbItem[] = []
): UserAccessibleSectionWithBreadcrumbs[] => {
  for (const {
    callToActionUrl,
    title,
    children,
    ...rest
  } of userAccessibleSections) {
    const isMatch = callToActionUrl === path;
    const breadcrumbs = [
      ...breadcrumbsParent,
      { title, ...(!isMatch && { href: callToActionUrl }) },
    ];

    if (isMatch) {
      return [{ ...rest, callToActionUrl, children, title, breadcrumbs }];
    }

    const foundItem = children?.length
      ? findByPath(children, path, breadcrumbs)
      : [];
    if (foundItem.length) return foundItem;
  }

  return [];
};

export const useUserAccessibleSections = () => {
  const isPendingRole = useUserRole() === "pending";
  const { updatable, ...other } = useUpdatables<UserAccessibleSection>({
    get: ["userAccessibleSections", {}, isPendingRole],
    transformToRichTypes: (record) => keysToCamelCase(record),
  });

  return {
    userAccessibleSections: filterPreviewPages(
      updatable.map(({ data }) => data) as UserAccessibleSection[]
    ),
    ...other,
  };
};

export const filterPreviewPages = (
  data: UserAccessibleSection[]
): UserAccessibleSection[] => {
  return data
    ?.filter((item) => {
      if (item?.featureFlag?.endsWith("-preview")) {
        const mainValue = item.featureFlag.replace("-preview", "");
        return !data.some((item) => item.featureFlag === mainValue);
      }
      return true;
    })
    .map((item) => {
      if (item?.featureFlag?.endsWith("-preview")) {
        const mainValue = item.title.replace(" (Preview)", "");
        return {
          ...item,
          title: mainValue,
          children: filterPreviewPages(item.children),
        };
      }
      return { ...item, children: filterPreviewPages(item.children) };
    });
};
