import "./COREDatePicker.less";
import React, { FC } from "react";
import { TimePickerProps, TimePicker, DatePickerProps } from "antd";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { RangePickerProps } from "antd/lib/date-picker";
import { WidthSize } from "./COREInput";
import classNames from "classnames";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { COREIcon } from "../Content/COREIcon";

export type SizeDatePicker = "sm" | "md" | "lg";
export const CORESizeToAntSize: Record<
  SizeDatePicker,
  DatePickerProps["size"]
> = {
  lg: "large",
  md: "middle",
  sm: "small",
};

export type CORETimepickerProps = Omit<
  TimePickerProps & RangePickerProps,
  "size"
> & {
  widthSize?: WidthSize;
  testID: TestID;
  size?: SizeDatePicker;
};

export const CORETimepicker: FC<CORETimepickerProps> = ({
  widthSize = "md",
  testID,
  className,
  popupClassName,
  dropdownClassName,
  value,
  defaultValue,
  size = "md",
  ...props
}) => {
  const defaultProps = {
    suffixIcon: (
      <>
        <div className={"default-suffix-icon"}>
          <COREIcon icon={icon({ name: "clock", style: "regular" })} />
        </div>
        <div className={"solid-suffix-icon"}>
          <COREIcon icon={icon({ name: "clock", style: "solid" })} />
        </div>
      </>
    ),
    clearIcon: (
      <COREIcon icon={icon({ name: "circle-xmark", style: "solid" })} />
    ),
    ...props,
    className: classNames(className, `input-width-${widthSize}`),
    dropdownClassName: classNames(
      dropdownClassName,
      "core-datepicker-dropdown"
    ),
    popupClassName: classNames(popupClassName, "core-datepicker-dropdown"),
  };

  const hasValue = !!value || !!defaultValue;
  if (hasValue)
    defaultProps.className = classNames(defaultProps.className, "has-value");

  return (
    <TestIDWrapper testID={testID} className={"core-datepicker"}>
      <TimePicker size={CORESizeToAntSize[size]} {...defaultProps} />
    </TestIDWrapper>
  );
};
