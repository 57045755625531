import { ReleaseNote } from "./shared/customHoooks/useUserReleaseNote";

export const releaseNoteData: ReleaseNote[] = [
  // // Example data
  // {
  //   version: "1.2.3",
  //   date: "11-Sep-2024",
  //   changes: [
  //     "Added feature A",
  //     "Fixed bug in feature B",
  //     "Improved performance for feature C",
  //   ],
  // },
  // {
  //   version: "1.2.2",
  //   date: "10-Sep-2024",
  //   changes: ["Fixed minor UI issue", "Updated documentation"],
  // },
  {
    version: "15.21.0",
    date: "31-Mar-2025",
    changes: [
      "LGC and STC REC Registry holdings data is now available within the platform. This information is updated daily and can be found in the Australian environmental market section under the registry tab.",
      "The Registry holdings table's configuration can be customised to your needs, with powerful column grouping controls and an array of filtering options that allow you to fully interrogate the data by attributes including owner, location, project, vintage, status and more.",
      "Brokerage clients can now access their trade transaction history with our markets team by opening your user profile panel and selecting the 'Transaction history' menu option.",
      "The mark as homepage button can now be deselected to change your custom home page preference back to the default CORE Markets Home page.",
      "Report pages have been updated to include an interactive table of contents. Click an item in the contents list to immediately jump to that location in the report.",
      "Bespoke reports have been integrated into the main reports pages and content feeds, instead of being found on a separate page.",
    ],
  },
  {
    version: "15.17.0",
    date: "07-Mar-2025",
    changes: [
      "Safeguard Mechanism Credits (SMCs) are now featured across the platform.",
      "SMC live pricing and other information can be found on on the 'Carbon Australia Overview' page.",
      "SMC end of day commentary and benchmark pricing has been added to the 'Carbon Daily Curve' page.",
      "SMC trade information is now available on the 'Trade Timeline' page and dashboard widget.",
      "SMC data is also available in 'Chart builder' and included in other various preset pricing dashboard widgets.",
      "Daily curve tables and widgets have received a visual update, along with the inclusion of change direction indicators for the data contained within.",
      "ACCU daily curve's method label now displays as the CORE Markets method code, hover your mouse on these column label to see the full name.",
    ],
  },
  {
    version: "15.10.0",
    date: "31-Jan-2025",
    changes: [
      "The new Australian Power Overview page is now available for electricity market subscribers, this page provides pricing and other information for both physical and financial products relating to Australia's NEM.",
      "Resolved an error that prevented the 'Mark as homepage' functionality from working correctly for some users.",
      "Fixed dashboard widget behaviour so that when changing the widget options it no longer moves to the bottom of the page.",
      "Fixed the display date bug for trades in the trade timeline dashboard widget and global homepage element.",
    ],
  },
  {
    version: "15.0.0",
    date: "29-Nov-2024",
    changes: [
      "This update brings a major revision to the way you can find markets information across the app.",
      "The new ‘Markets’ section replaces ‘Market Prices’ and unifies market data including live pricing, daily curves and trade timeline into a common navigable location.",
      "Within ‘Markets’ each product class is populated with geographic markets providing all relevant information in a consolidated manner.",
      "Key features including ‘Project Marketplace’ and ‘Chart Builder’ have been elevated to top level elements in the main menu panel for easier access.",
      "‘Trade log’ has been renamed to ‘Trade timeline’ and is now located in the Markets section. The capability of this feature has been increased significantly, allowing you to organise the data how you prefer, including: grouping by attributes, re-ordering the columns, show or hide columns that are not relevant to you and adjust the row density to your preference.",
    ],
  },
  {
    version: "14.0.0",
    date: "15-Nov-2024",
    changes: [
      "Introducing the global ‘CORE Markets home’ page. This page showcases the latest live pricing and insights from our Markets and Advisory teams.",
      "The ‘Mark as favourite’ dashboard functionality has been upgraded to ‘Mark as homepage’. You can now select any page that features a home icon (found on the top right of the page) to be your landing page each time you log in.",
      "The CORE Markets ‘Insights’ section is now available. You can access premium content – including reports, market updates and other insights from our experts.",
      "ACCU Market Forecast subscribers can now access quarterly releases of this report via the ‘Reports’ page.",
      "Create dashboards quickly and easily with a selection of new templates found using the ‘Create New’ dashboard menu button.",
      "Select from a range of new and improved dashboard widgets that will power-up your custom dashboards experience.",
      "Visit the new ‘Markets snapshot’ page to see live pricing across a range of the most active products in market.",
    ],
  },
];
