/* eslint-disable react/prop-types */
import React, { ReactNode, RefObject, useContext, useMemo } from "react";
import {
  MaterialReactTable,
  MRT_Cell as MRTCell,
  MRT_Column as MRTColumn,
  type MRT_ColumnDef as MRTColumnDef,
  MRT_ColumnOrderState as MRTColumnOrderState,
  MRT_DensityState as MRTDensityState,
  MRT_GroupingState as MRTGroupingState,
  MRT_Icons as MRTIcons,
  MRT_Row as MRTRow,
  MRT_RowData as MRTRowData,
  MRT_ShowHideColumnsButtonProps as MRTShowHideColumnsButtonProps,
  MRT_TableInstance as MRTTableInstance,
  MRT_TableOptions as MRTTableOptions,
  MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MRTToggleFiltersButton,
  MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
  MRT_VisibilityState as MRTVisibilityState,
  useMaterialReactTable,
} from "material-react-table";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { CORECSVButton } from "../Action/CORECSVButton";
import { COREIcon } from "./COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./CORETable.less";
import "./MaterialTableCustomStyle/MRTMenu.less";
import "./MaterialTableCustomStyle/MRTTooltip.less";
import { Col, Row, Space } from "antd";
import { CORETag } from "./CORETag";
import { COREBody, COREBodyTruncateWithTooltip } from "../Typography/COREBody";
import { grey100, grey120, white } from "./COREColour";
import { COREPagination } from "../Navigation/COREPagination";
import { COREDivider } from "../Layout/COREDivider";
import classNames from "classnames";
import { CORETooltip } from "../Overlay/CORETooltip";
import { COREButton } from "../Action/COREButton";
import { COREDropdownMenu, DropdownMenuItem } from "../Form/COREDropdownMenu";
import { COREMenuItem } from "../Navigation/COREMenuItem";
import { CORESwitch } from "../Form/CORESwitch";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { COREMaterialTableIsFullScreenModeContext } from "../../modules/pages/layouts/PageLayout";
import DensityLargeIcon from "@mui/icons-material/DensityLarge";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import DensitySmallIcon from "@mui/icons-material/DensitySmall";
import { CORELoading } from "../Feedback/CORELoading";
import { useLocalStorageUntilLogoutPerKey } from "../../shared/customHoooks/useLocalStorageUntilLogout";
import { useLocalStorage } from "react-use";
import { Updater } from "@tanstack/table-core/src/types";
import {
  ColumnOrderState,
  GroupingState,
  OnChangeFn,
  PaginationState,
  SortingState,
  VisibilityState,
} from "@tanstack/table-core";

export type CellProps<TData extends MRTRowData> = {
  cell: MRTCell<TData, unknown>;
  column: MRTColumn<TData, unknown>;
  renderedCellValue: ReactNode;
  row: MRTRow<TData>;
  staticColumnIndex?: number;
  staticRowIndex?: number;
  rowRef?: RefObject<HTMLTableRowElement>;
  table: MRTTableInstance<TData>;
};

export type CORETableColumn<T extends Record<string, unknown>> =
  MRTColumnDef<T>;

export type CORETableProps<RecordType extends Record<string, unknown>> =
  MRTTableOptions<RecordType> & {
    testID: TestID;
    enableDownloadCSV?: boolean;
    csvFileName?: string;
    onRow?: (values: RecordType) => void;
    customCSVData?: {
      headers: Partial<Record<keyof RecordType, string>>;
      transform: (csvData: RecordType[]) => Record<string, unknown>[];
    };
    localStorageKey: string;
    enableRememberPaginate?: boolean;
    enableRememberColumnOrder?: boolean;
    enableRememberColumnVisibility?: boolean;
    enableRememberDensity?: boolean;
    enableRememberSorting?: boolean;
    enableRememberGrouping?: boolean;
    isLoading?: boolean;
    customPagination?: ReactNode;
    defaultPaginate?: TableSettingUntilLogout["pagination"];
    customDownloadCSVButton?: ReactNode;
  };

type GroupedCellProps<RecordType extends Record<string, unknown>> = {
  cell: MRTCell<RecordType, unknown>;
  column: MRTColumn<RecordType, unknown>;
  row: MRTRow<RecordType>;
  table: MRTTableInstance<RecordType>;
};

type TableSettingUntilLogout = {
  pagination?: {
    pageSize: number;
    pageIndex: number;
  };
};

type TableSetting = {
  grouping?: MRTGroupingState;
  columnOrder?: MRTColumnOrderState;
  columnVisibility?: MRTVisibilityState;
  density?: MRTDensityState;
  sorting?: SortingState;
};

export type CustomToolbarMenuProps<T extends Record<string, unknown>> = {
  table: MRTTableInstance<T>;
} & Pick<
  CORETableProps<T>,
  | "testID"
  | "data"
  | "enableDownloadCSV"
  | "csvFileName"
  | "customCSVData"
  | "customDownloadCSVButton"
>;

export const alignColumn = <T extends Record<string, unknown>>(
  align: "center" | "left" | "right"
): Pick<
  CORETableColumn<T>,
  "muiTableBodyCellProps" | "muiTableHeadCellProps"
> => ({
  muiTableBodyCellProps: {
    align: align,
  },
  muiTableHeadCellProps: {
    align: align,
  },
});

export const mapDataToVisibleColumn = <T extends Record<string, unknown>>(
  data: T[],
  keys: string[]
) => {
  return data.map((item) =>
    keys.map(
      (key) =>
        key.split(".").reduce<unknown>((acc, k) => {
          if (acc && typeof acc === "object" && k in acc) {
            return (acc as Record<string, unknown>)[k] ?? "";
          }
          return "";
        }, item) as string | number
    )
  );
};

export const gettingVisibleColumnKeys = <
  T extends Record<string, unknown> = {}
>(
  columns: ReturnType<
    CustomToolbarMenuProps<T>["table"]["getVisibleFlatColumns"]
  >
) => {
  return columns
    .filter((t) => t && t.id && !t.id.startsWith("mrt-row"))
    .map((t) => t.id);
};

export const gettingVisibleColumnHeaders = <
  T extends Record<string, unknown> = {}
>(
  columns: ReturnType<
    CustomToolbarMenuProps<T>["table"]["getVisibleFlatColumns"]
  >
) => {
  return columns
    .filter((t) => t && t.id && !t.id.startsWith("mrt-row"))
    .map((t) => t.columnDef.header as string);
};

type FullscreenButtonProps<T extends Record<string, unknown>> = {
  table: MRTTableInstance<T>;
  testID: TestID;
};

const FullscreenButton = <T extends Record<string, unknown>>({
  table,
  testID,
}: FullscreenButtonProps<T>) => {
  const { isFullScreenMode = false, setIsFullScreenMode } = useContext(
    COREMaterialTableIsFullScreenModeContext
  );

  const {
    options: {
      icons: { FullscreenExitIcon, FullscreenIcon },
    },
  } = table;

  return (
    <CORETooltip
      position="bottom"
      width="auto"
      title=""
      message="Toggle full screen"
      testID={`${testID}-full-screen-tooltip`}
    >
      <COREButton
        type="action"
        size="lg"
        icon={isFullScreenMode ? <FullscreenExitIcon /> : <FullscreenIcon />}
        onClick={() => {
          setIsFullScreenMode && setIsFullScreenMode(!isFullScreenMode);
          table.setIsFullScreen(!isFullScreenMode);
        }}
        testID={`${testID}-full-screen-button`}
      />
    </CORETooltip>
  );
};

const getCustomCSVData = <T extends Record<string, unknown>>(
  customCSVData: CORETableProps<T>["customCSVData"]
): {
  keys: string[];
  headers: string[];
  transform: (d: T[]) => Record<string, unknown>[];
} => {
  const { headers, transform } = customCSVData ?? {};

  return {
    keys: Object.keys(headers ?? ""),
    headers: Object.values(headers ?? "") as string[],
    transform: transform ?? ((d: T[]) => d),
  };
};

const DownloadCSVButton = <T extends Record<string, unknown> = {}>({
  table,
  data,
  testID,
  customCSVData,
  csvFileName = "CSV Data",
}: CustomToolbarMenuProps<T>) => {
  const columns = table.getVisibleFlatColumns();
  const { keys, headers, transform } = useMemo(
    () => getCustomCSVData(customCSVData),
    [customCSVData]
  );
  const dataResult = useMemo(
    () => (transform ? transform(data) : data),
    [data, transform]
  );

  const visibleColumnKeys = useMemo(
    () => (keys.length ? keys : gettingVisibleColumnKeys(columns)),
    [columns, keys]
  );
  const visibleColumnHeaders = useMemo(
    () => (headers.length ? headers : gettingVisibleColumnHeaders(columns)),
    [columns, headers]
  );
  const dataSource = useMemo(
    () => mapDataToVisibleColumn(dataResult, visibleColumnKeys),
    [dataResult, visibleColumnKeys]
  );

  return (
    <CORECSVButton
      testID={`${testID}-csv-download`}
      filename={csvFileName}
      getData={() => ({
        headers: visibleColumnHeaders,
        data: dataSource,
      })}
      icon={<COREIcon icon={icon({ name: "file-csv", style: "regular" })} />}
      key={`${testID}-csv-download`}
      buttonType="action"
      buttonSize="lg"
    />
  );
};

const CustomToolbarMenu = <T extends Record<string, unknown>>({
  table,
  data,
  testID,
  enableDownloadCSV,
  csvFileName,
  customCSVData,
  customDownloadCSVButton,
}: CustomToolbarMenuProps<T>) => {
  const {
    options: {
      columnFilterDisplayMode,
      enableColumnFilters,
      enableColumnOrdering,
      enableColumnPinning,
      enableDensityToggle,
      enableFilters,
      enableFullScreenToggle,
      enableGlobalFilter,
      enableHiding,
      initialState,
    },
  } = table;
  return (
    <>
      {enableFilters &&
        enableGlobalFilter &&
        !initialState?.showGlobalFilter && (
          <MRTToggleGlobalFilterButton table={table} />
        )}
      {enableFilters &&
        enableColumnFilters &&
        columnFilterDisplayMode !== "popover" && (
          <MRTToggleFiltersButton table={table} />
        )}
      {(enableHiding || enableColumnOrdering || enableColumnPinning) && (
        <CustomShowHideColumnsButton table={table} testID={testID} />
      )}
      {enableDensityToggle && <MRTToggleDensePaddingButton table={table} />}
      {enableFullScreenToggle && (
        <FullscreenButton table={table} testID={testID} />
      )}
      {enableDownloadCSV && (
        <CORETooltip
          position="bottom"
          width="auto"
          title=""
          message="Download CSV"
          testID={`${testID}-csv-tooltip`}
        >
          {customDownloadCSVButton ? (
            customDownloadCSVButton
          ) : (
            <DownloadCSVButton
              table={table}
              data={data}
              testID={testID}
              csvFileName={csvFileName}
              customCSVData={customCSVData}
            />
          )}
        </CORETooltip>
      )}
    </>
  );
};

type CustomShowHideColumnsButtonProps<TData extends MRTRowData> = {
  testID: TestID;
  table: MRTShowHideColumnsButtonProps<TData>["table"];
};

const CustomShowHideColumnsButton = <TData extends MRTRowData>({
  table,
  testID,
}: CustomShowHideColumnsButtonProps<TData>) => {
  const {
    options: {
      icons: { ViewColumnIcon },
    },
  } = table;
  const columns = table.getAllColumns().filter((c) => {
    return (
      c.columnDef.columnDefType !== "display" &&
      c.columnDef.visibleInShowHideMenu !== false
    );
  });
  const currentColumnVisibility = columns.reduce(
    (acc: Record<string, boolean>, c) => {
      const id = c.columnDef.id ?? c.columnDef.accessorKey;
      acc[id as string] = c.getIsVisible();
      return acc;
    },
    { "mrt-row-expand": false }
  );

  const menus: DropdownMenuItem[] = columns.map((c) => {
    const id = c.columnDef.id ?? c.columnDef.accessorKey;
    return {
      closeWhenClick: false,
      component: (
        <COREMenuItem testID={`${testID}-menu-item`} key={id}>
          <Space size={8} direction="horizontal">
            <CORESwitch
              size="sm"
              checked={c.getIsVisible()}
              onChange={(value) => {
                if (id) {
                  table.setColumnVisibility({
                    ...currentColumnVisibility,
                    [id]: value,
                  });
                }
              }}
              testID={`${testID}-menu-item-switch-${id}`}
              disabled={!c.getCanHide()}
            />
            <COREBody marginBottom={false} type="p3">
              {c.columnDef.header}
            </COREBody>
          </Space>
        </COREMenuItem>
      ),
    };
  });

  return (
    <>
      <COREDropdownMenu
        interactionsMenu={menus}
        testID={`${testID}-show-hide-column-dropdown`}
        isDisabled={false}
        placement="bottomLeft"
        overlayStyle={{
          maxHeight: 400,
          overflowY: "scroll",
        }}
      >
        <COREButton
          displayTooltip
          tooltipTitle="Show/Hide columns"
          tooltipPosition="bottom"
          tooltipWidth="auto"
          type="action"
          size="lg"
          icon={<ViewColumnIcon />}
          testID={`${testID}-show-hide-button`}
        />
      </COREDropdownMenu>
    </>
  );
};

type CustomColumnGroupingToolbarProps<T extends Record<string, unknown>> = {
  table: MRTTableInstance<T>;
  columns: CORETableProps<T>["columns"];
  testID: TestID;
};

const CustomColumnGroupingToolbar = <T extends Record<string, unknown>>({
  table,
  columns,
  testID,
}: CustomColumnGroupingToolbarProps<T>) => {
  const { grouping } = table.getState();
  const { setGrouping } = table;
  const groupingColumns = grouping.map((group) =>
    columns.find((column) => column.accessorKey === group)
  );
  const handleCloseTag = (accessorKey: string) => {
    setGrouping(grouping.filter((item) => item !== accessorKey));
  };
  return (
    <Space>
      <COREBody
        testID={`${testID}-grouped-by-title`}
        type="p3"
        strong
        marginBottom={false}
      >
        Grouped by
      </COREBody>
      {groupingColumns.map((groupingColumn) => (
        <CORETag
          testID={`${testID}-group-tag-${groupingColumn?.accessorKey}`}
          key={groupingColumn?.header}
          type="basic"
          label={groupingColumn?.header}
          closable
          onClose={() => {
            groupingColumn?.accessorKey &&
              handleCloseTag(groupingColumn?.accessorKey);
          }}
        />
      ))}
    </Space>
  );
};

type IconProps = JSX.IntrinsicAttributes &
  Omit<FontAwesomeIconProps, "size"> & {
    size?: "lg" | "md" | "sm" | "xxs" | "xs" | "xl" | "xxl";
    animate?:
      | "none"
      | "spin"
      | "spinPulse"
      | "spinReverse"
      | "pulse"
      | "beat"
      | "fade"
      | "beatFade"
      | "bounce"
      | "shake";
  } & {
    children?: React.ReactNode | undefined;
  };

const customizeIcon: Partial<MRTIcons> = {
  ArrowDownwardIcon: (props: IconProps) => {
    return (
      <COREIcon
        {...props}
        className={classNames(
          `core-table-column-header-icon`,
          props?.className
        )}
        icon={icon({ name: "arrow-down", style: "regular" })}
      />
    );
  },
  // ArrowRightIcon: ,
  // CancelIcon: ,
  // ChevronLeftIcon: ,
  // ChevronRightIcon: ,
  ClearAllIcon: (props: IconProps) => (
    <COREIcon
      color={grey120}
      {...props}
      icon={icon({ name: "bars-staggered", style: "regular" })}
    />
  ),
  // CloseIcon: ,
  // ContentCopy: ,
  DensityLargeIcon: (props: IconProps) => (
    <DensityLargeIcon className="core-table-icon-grey-120" />
  ),
  DensityMediumIcon: (props: IconProps) => (
    <DensityMediumIcon className="core-table-icon-grey-120" />
  ),
  DensitySmallIcon: (props: IconProps) => (
    <DensitySmallIcon className="core-table-icon-grey-120" />
  ),
  DragHandleIcon: (props: IconProps) => (
    <COREIcon
      {...props}
      className={classNames(`core-table-column-header-icon`, props?.className)}
      icon={icon({ name: "grip-lines", style: "regular" })}
    />
  ),
  DynamicFeedIcon: (props: IconProps) => (
    <COREIcon
      color={grey120}
      {...props}
      icon={icon({ name: "window-restore", style: "solid" })}
    />
  ),
  // EditIcon: ,
  ExpandMoreIcon: (props: IconProps) => (
    <COREIcon
      color={grey100}
      {...props}
      icon={icon({ name: "caret-up", style: "solid" })}
    />
  ),
  // FilterAltIcon: ,
  // FilterListIcon: ,
  // FilterListOffIcon: ,
  // FirstPageIcon: ,
  FullscreenExitIcon: (props: IconProps) => (
    <COREIcon
      color={grey120}
      {...props}
      icon={icon({ name: "compress", style: "regular" })}
    />
  ),
  FullscreenIcon: (props: IconProps) => (
    <COREIcon
      color={grey120}
      {...props}
      icon={icon({ name: "expand", style: "regular" })}
    />
  ),
  // KeyboardDoubleArrowDownIcon: ,
  // LastPageIcon: ,
  // MoreHorizIcon: ,
  MoreVertIcon: (props: IconProps) => (
    <COREIcon
      {...props}
      className={classNames(`core-table-column-header-icon`, props?.className)}
      icon={icon({ name: "ellipsis-vertical", style: "regular" })}
    />
  ),
  // PushPinIcon: ,
  RestartAltIcon: (props: IconProps) => (
    <COREIcon
      color={grey120}
      {...props}
      icon={icon({ name: "arrow-rotate-left", style: "regular" })}
    />
  ),
  // SaveIcon: ,
  // SearchIcon: ,
  // SearchOffIcon: ,
  SortIcon: (props: IconProps) => (
    <COREIcon
      color={grey120}
      {...props}
      icon={icon({ name: "bars-sort", style: "regular" })}
    />
  ),
  SyncAltIcon: (props: IconProps) => (
    <COREIcon
      {...props}
      style={{
        ...props.style,
        transform: `rotate(0deg) scaleX(0.9) translateX(-1px)`,
      }}
      className={classNames(`core-table-column-header-icon`, props?.className)}
      icon={icon({ name: "arrow-up-arrow-down", style: "regular" })}
    />
  ),
  ViewColumnIcon: (props: IconProps) => (
    <COREIcon
      color={grey120}
      {...props}
      icon={icon({ name: "columns-3", style: "regular" })}
    />
  ),
  VisibilityOffIcon: (props: IconProps) => (
    <COREIcon
      color={grey120}
      {...props}
      icon={icon({ name: "eye-slash", style: "regular" })}
    />
  ),
};

type TablePaginationProps<T extends Record<string, unknown>> = {
  table: MRTTableInstance<T>;
  testID: TestID;
  setTableSettingUntilLogout?: (values: TableSettingUntilLogout) => void;
};

const TablePagination = <T extends Record<string, unknown>>({
  table,
  testID,
  setTableSettingUntilLogout,
}: TablePaginationProps<T>) => {
  const {
    pagination: { pageIndex, pageSize },
  } = table.getState();
  const total = table.getRowCount();
  return (
    <COREPagination
      testID={`${testID}-pagination`}
      total={total}
      current={pageIndex + 1}
      showSizeChanger
      pageSize={pageSize}
      onChange={(newPage, newPageSize) => {
        const pageIndex = newPage - 1;
        table.setPageIndex(pageIndex);
        table.setPageSize(newPageSize);
        setTableSettingUntilLogout &&
          setTableSettingUntilLogout({
            pagination: {
              pageIndex: pageIndex,
              pageSize: newPageSize,
            },
          });
      }}
    />
  );
};

export const getHeightByDensity = (density: MRTDensityState) => {
  switch (density) {
    case "compact":
      return "38px";
    case "comfortable":
      return "48px";
    case "spacious":
      return "58px";
    default:
      return "48px";
  }
};

export const CORETable = <RecordType extends Record<string, unknown> = {}>({
  testID,
  enableDownloadCSV,
  csvFileName,
  columns,
  data,
  state,
  initialState,
  enableGrouping,
  enablePagination = true,
  enableEditing,
  editDisplayMode,
  onRow,
  customCSVData,
  localStorageKey,
  isLoading,
  enableRememberPaginate = false,
  enableRememberColumnOrder = false,
  enableRememberColumnVisibility = false,
  enableRememberDensity = false,
  enableRememberSorting = false,
  enableRememberGrouping = false,
  defaultPaginate = {
    pageSize: 20,
    pageIndex: 0,
  },
  customPagination,
  customDownloadCSVButton,
  ...props
}: CORETableProps<RecordType>) => {
  const initialTableSetting = {
    grouping: initialState?.grouping ?? [],
    columnOrder: initialState?.columnOrder ?? [],
    columnVisibility: initialState
      ? { ...initialState.columnVisibility, "mrt-row-expand": false }
      : {},
    density: initialState?.density ?? "compact",
    sorting: initialState?.sorting ?? [],
  };
  const [tableSettingUntilLogout, setTableSettingUntilLogout] =
    useLocalStorageUntilLogoutPerKey<TableSettingUntilLogout | undefined>(
      "coretable",
      {
        key: localStorageKey,
        initialValue: {
          pagination: defaultPaginate,
        },
      }
    );
  const [tableSetting, setTableSetting] = useLocalStorage<TableSetting>(
    `${localStorageKey}-permanent`,
    initialTableSetting
  );
  const [visibilitySetting, setVisibilitySetting] =
    useLocalStorage<VisibilityState>(
      `${localStorageKey}-visibility-permanent`,
      initialState
        ? { ...initialState.columnVisibility, "mrt-row-expand": false }
        : {}
    );

  const [columnOrderSetting, setColumnOrderSetting] =
    useLocalStorage<ColumnOrderState>(
      `${localStorageKey}-columnOrder-permanent`,
      initialState?.columnOrder ?? []
    );

  const columnWithGroup: CORETableProps<RecordType>["columns"] = columns.map(
    (c) => ({
      Header: ({ column, header, table }) => {
        if (column && column.columnDef && column.columnDef.header) {
          return (
            <COREBodyTruncateWithTooltip
              type="p3"
              color={white}
              strong
              marginBottom={false}
              testID={`${testID}-${column.columnDef.header}-header`}
              truncateAfter={1}
            >
              {column.columnDef.header}
            </COREBodyTruncateWithTooltip>
          );
        }
        return null;
      },
      Cell: ({ cell, column, renderedCellValue, row, table }) => {
        return (
          <COREBodyTruncateWithTooltip
            type="p2"
            marginBottom={false}
            testID={`${testID}-${renderedCellValue}`}
            truncateAfter={1}
            strong={column.getIndex() === 0}
          >
            {renderedCellValue}
          </COREBodyTruncateWithTooltip>
        );
      },
      ...c,
      GroupedCell: ({
        cell,
        column,
        row,
        table,
      }: GroupedCellProps<RecordType>) => {
        const renderCell = column.columnDef.Cell
          ? column.columnDef.Cell({
              cell,
              column,
              renderedCellValue: cell.renderValue(),
              row,
              table,
            })
          : cell.renderValue();
        const handleToggleExpanded = () => {
          row.toggleExpanded();
        };
        const {
          options: {
            icons: { ExpandMoreIcon },
          },
        } = table;
        const iconRotate = row.getIsExpanded() ? "-180deg" : "90deg";
        const iconStyle = {
          transform: `rotate(${iconRotate})`,
          transition: "transform 150ms",
        };
        return (
          <Space>
            {row.getCanExpand() && (
              <COREButton
                type={"action"}
                size={"lg"}
                onClick={(e) => {
                  handleToggleExpanded();
                  e.stopPropagation();
                }}
                icon={<ExpandMoreIcon style={iconStyle} />}
                testID={`${testID}-png`}
              />
            )}
            <COREBodyTruncateWithTooltip
              type="p2"
              marginBottom={false}
              testID={`${testID}-cell-value-text`}
              truncateAfter={1}
            >
              {renderCell}
            </COREBodyTruncateWithTooltip>
          </Space>
        );
      },
    })
  );
  const updateTableSetting = <T,>(
    updaterOrValue: Updater<T>,
    key: keyof TableSetting
  ) => {
    const values = tableSetting ?? initialTableSetting;
    setTableSetting(() => {
      const newValue =
        updaterOrValue instanceof Function
          ? updaterOrValue(values[key] as T)
          : updaterOrValue;
      return { ...values, [key]: newValue };
    });
  };

  const getLocalStorageTableSetting = (
    enable: boolean,
    key: keyof TableSetting,
    tableSetting?: TableSetting
  ) => {
    const defaultValue = state ? state[key] : undefined;
    return enable && tableSetting && tableSetting[key]
      ? tableSetting[key]
      : defaultValue;
  };

  const paginationState: PaginationState | undefined = enableRememberPaginate
    ? tableSettingUntilLogout?.pagination ?? state?.pagination
    : undefined;
  const columnVisibilityState = enableRememberColumnVisibility
    ? visibilitySetting
    : state?.columnVisibility
    ? { "mrt-row-expand": false, ...state?.columnVisibility }
    : undefined;
  const columnOrderState = enableRememberColumnOrder
    ? columnOrderSetting
    : state?.columnOrder;
  const groupingState = getLocalStorageTableSetting(
    enableRememberGrouping,
    "grouping",
    tableSetting
  ) as GroupingState | undefined;
  const densityState = getLocalStorageTableSetting(
    enableRememberDensity,
    "density",
    tableSetting
  ) as MRTDensityState | undefined;
  const sortingState = getLocalStorageTableSetting(
    enableRememberSorting,
    "sorting",
    tableSetting
  ) as SortingState | undefined;

  const modifyColumGroup: MRTColumnDef<RecordType, any>[] = columnWithGroup.map(
    (column) => {
      if (
        groupingState &&
        groupingState.includes(column.accessorKey as string)
      ) {
        return {
          ...column,
          muiTableBodyCellProps: {
            ...(column.muiTableBodyCellProps || {}),
            align: "left",
          },
        };
      }
      return column;
    }
  );

  const displayColums =
    groupingState && groupingState.length > 0
      ? modifyColumGroup
      : columnWithGroup;
  const tableState = {
    isLoading: isLoading,
    ...(columnVisibilityState && {
      columnVisibility: {
        ...columnVisibilityState,
        "mrt-row-expand": false,
      },
    }),
    ...(paginationState && { pagination: paginationState }),
    ...(columnOrderState && { columnOrder: columnOrderState }),
    ...(groupingState && { grouping: groupingState }),
    ...(densityState && { density: densityState }),
    ...(sortingState && { sorting: sortingState }),
    ...state,
  };

  const tableInitState = {
    ...initialState,
    columnVisibility: {
      ...initialState?.columnVisibility,
      "mrt-row-expand": false,
    },
    pagination: initialState?.pagination
      ? initialState?.pagination
      : defaultPaginate,
  };

  const table = useMaterialReactTable({
    columns: displayColums,
    data,
    enableGrouping,
    icons: customizeIcon,
    paginationDisplayMode: "custom",
    enableBottomToolbar: false,
    enableGlobalFilter: false,
    enableFilters: false,
    enableFullScreenToggle: true,
    enablePagination: enablePagination,
    muiCircularProgressProps: {
      Component: <CORELoading />,
    },
    enableEditing,
    editDisplayMode,
    state: tableState,
    initialState: tableInitState,
    renderToolbarInternalActions: ({ table }) => (
      <CustomToolbarMenu
        customDownloadCSVButton={customDownloadCSVButton}
        customCSVData={customCSVData}
        csvFileName={csvFileName}
        enableDownloadCSV={enableDownloadCSV}
        table={table}
        data={data}
        testID={testID}
      />
    ),
    renderToolbarAlertBannerContent: ({
      table,
      groupedAlert,
      selectedAlert,
    }) => (
      <CustomColumnGroupingToolbar
        testID={testID}
        table={table}
        columns={columns}
      />
    ),
    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        ...(enableEditing &&
          editDisplayMode === "cell" && {
            onClick: () => {
              table.setEditingCell(cell);
              queueMicrotask(() => {
                const textField = table.refs.editInputRefs.current[column.id];
                if (textField) {
                  textField.focus();
                  textField.select?.();
                }
              });
            },
          }),
        sx: {
          minHeight: getHeightByDensity(table.getState().density),
          paddingY: 0,
        },
      };
    },
    muiTableHeadCellProps: ({ column, table }) => {
      return {
        sx: {
          height: getHeightByDensity(table.getState().density),
        },
      };
    },
    muiTableBodyRowProps: ({ row }) => ({
      ...(onRow && {
        onClick: () => {
          onRow(data[parseInt(row.id)]);
        },
        sx: {
          cursor: "pointer",
        },
      }),
    }),
    layoutMode: "grid",
    ...(enableRememberColumnVisibility && {
      onColumnVisibilityChange:
        setVisibilitySetting as OnChangeFn<VisibilityState>,
    }),
    ...(enableRememberColumnOrder && {
      onColumnOrderChange:
        setColumnOrderSetting as OnChangeFn<ColumnOrderState>,
    }),
    ...(enableRememberGrouping && {
      onGroupingChange: (updaterOrValue) => {
        updateTableSetting(updaterOrValue, "grouping");
      },
    }),
    ...(enableRememberDensity && {
      onDensityChange: (updaterOrValue) => {
        updateTableSetting(updaterOrValue, "density");
      },
    }),
    ...(enableRememberSorting && {
      onSortingChange: (updaterOrValue) => {
        updateTableSetting(updaterOrValue, "sorting");
      },
    }),
    ...props,
  });

  const { isFullScreen } = table.getState();

  return (
    <TestIDWrapper
      testID={testID}
      className={classNames("core-table", {
        "core-table-full-screen": isFullScreen,
      })}
    >
      <Row>
        <Col span={24}>
          <MaterialReactTable table={table} />
        </Col>
      </Row>
      {(enablePagination || customPagination) && (
        <>
          <Row>
            <Col span={24}>
              <COREDivider space="md" />
            </Col>
          </Row>
          <Row>
            <Col span={24} className="core-table-right-align">
              {customPagination || (
                <TablePagination
                  testID={testID}
                  table={table}
                  setTableSettingUntilLogout={setTableSettingUntilLogout}
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </TestIDWrapper>
  );
};
