import { environmental_products as adminMarketPriceTableDefinitions } from "../openapi-typescript/admin/models/environmental_products";
import { environmental_products as approvedMarketPriceTableDefinitions } from "../openapi-typescript/approved/models/environmental_products";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHash } from "react-use";
import { NumberFormat } from "./globals";

type YearNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
export type MagnitudeTableEmission = "t" | "kt" | "Mt" | "Gt" | "auto";
export type Year = `20${YearNumber}${YearNumber}`;
export type SpacingSizes = {
  xxs: number;
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
};
export type SpacingName = keyof SpacingSizes;
export const spacing: SpacingSizes = {
  xxs: 4,
  xs: 8,
  sm: 12,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 48,
};

export function findLast<A>(
  array: A[],
  predicate: (item: A, index: number, array: A[]) => boolean
) {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return array[l];
  }
  return null;
}

export function diff(a, b) {
  return Math.abs(a - b);
}
export const COREMARKETS_URL: string = "https://coremarkets.co";
export const COREMARKETS_TERMS_OF_USE_URL: string =
  "https://coremarkets.co/terms-of-use";
export const PLATFORM_TERMS_OF_USER_URL: string =
  "https://coremarkets.co/platform-terms";
export const COREMARKETS_PRIVACY_POLICY_URL: string =
  "https://coremarkets.co/privacy-policy";
export const BROKER_HOTLINE = "+61-2-9135-4932";
export const BROKER_HOTLINE_DISPLAY = "+61 (2) 9135 4932";
export const PRODUCT_NAME = "CORE Markets";
export const WHITELIST_URLS = [
  "https://api.coremarkets.co",
  "https://api-uat.coremarkets.co",
];
export const HELP_URL = "https://docs.coremarkets.co";
export const DEFAULT_HOMEPAGE_URL = "/home";

type Region = ("Qld" | "NSW" | "Vic" | "Tas" | "SA")[];

export const contentHeaderForPriceTable: Region = ["Qld", "NSW", "Vic", "SA"];
export const carbonProducts: Exclude<
  (
    | adminMarketPriceTableDefinitions
    | approvedMarketPriceTableDefinitions
  )["name"],
  undefined
>[] = ["ACCU", "SMC", "NZU", "CER", "GS", "VCS", "REDD+"];

export const EnviroProducts: Exclude<
  (
    | adminMarketPriceTableDefinitions
    | approvedMarketPriceTableDefinitions
  )["name"],
  undefined
>[] = ["STC", "LGC", "ESC", "VEEC", "PRC", "IREC"];

export const FEATURE_FLAG_DAILY_CURVES_CARBON = "daily-curves-carbon";

export const yearRange = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const centralAustraliaLocation: number[] = [
  -24.58460252143749, 134.7907170759688,
];
export const internationalLocation: number[] = [
  30.44867367928756, 15.110581440040985,
];
export const newZealandLocation: number[] = [
  -41.57436130598913, -185.97548150837036,
];

export const groupBy = <T>(
  xs: T[],
  getGroup: (x: T) => string
): { [key: string]: Array<T> } =>
  xs.reduce((rv, x) => {
    const key = getGroup(x);
    (rv[key] = rv[key] || []).push(x);
    return rv;
  }, {});

export const uniqueByKey = <A>(array: A[], key: string | number) => [
  ...new Map(array.map((x) => [x[key], x])).values(),
];

export const uniqueByMultipleKeys = <A extends object>(
  array: A[],
  keys: (keyof A)[]
) => [...new Map(array.map((x) => [keys.map((k) => x[k]).join(), x])).values()];

export const toCamelCase = (str: string): string => {
  return str.replace(/([-_][a-z0-9])/g, (c) => {
    return c.toUpperCase().replace("-", "").replace("_", "");
  });
};

export const keysToCamelCase = (o) => {
  if (Array.isArray(o)) {
    return o.map((i) => {
      return keysToCamelCase(i);
    });
  } else if (o instanceof Object) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toCamelCase(k)] = keysToCamelCase(o[k]);
    });

    return n;
  }

  return o;
};

export const toSnake = (str: string): string => {
  return str.replace(/[A-Z]/g, (c) => {
    return "_" + c.toLowerCase();
  });
};

export const keysToSnake = (o) => {
  if (Array.isArray(o)) {
    return o.map((i) => {
      return keysToSnake(i);
    });
  } else if (o instanceof Object) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toSnake(k)] = keysToSnake(o[k]);
    });

    return n;
  }

  return o;
};

export const removeWhiteSpace = (str: string) => {
  if (!str) return str;
  return str.replace(/^\s+/g, "");
};

export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.substring(1);

const toReplaceDoubleQuote = (str: string): string => {
  return str.replace(/"/g, '""');
};

export const escapeDoubleQuotes = (o) => {
  if (Array.isArray(o)) {
    if (Array.isArray(o[0])) {
      const d: string[][] = [];

      o.map((i) => {
        const c: string[] = [];

        i.map((j) => c.push(toReplaceDoubleQuote(j?.toString() ?? "")));
        return d.push(c);
      });

      return d;
    } else if (typeof o[0] === "string") {
      const d: string[] = [];

      o.map((i) => d.push(toReplaceDoubleQuote(i?.toString() ?? "")));

      return d;
    } else {
      const d: object[] = [];

      o.map((i) => {
        const n = {};
        Object.keys(i).forEach((k) => {
          n[k] = toReplaceDoubleQuote(i[k]?.toString() ?? "");
        });
        return d.push(n);
      });
      return d;
    }
  }

  return o;
};

export const currencyLongName = (currency: string) =>
  ({
    AUD: "Australian Dollars",
    USD: "American Dollars",
    NZD: "New Zealand Dollars",
    CAD: "Canadian Dollars",
    EUR: "Euros",
  }[currency]);

export const useOpenClose: (init: boolean) => {
  isOpen: boolean;
  closer: () => void;
  opener: () => void;
} = (init: boolean) => {
  const [isOpen, setIsOpen] = useState<boolean>(init);
  const opener = useCallback(() => setIsOpen(true), [setIsOpen]);
  const closer = useCallback(() => setIsOpen(false), [setIsOpen]);
  return { isOpen, opener, closer };
};

export const titleFontFamily = "Inter, sans-serif";
export const bodyFontFamily = "Inter, sans-serif";

export const useSearchParams = <P extends Record<string, string>>(): P => {
  const location = useLocation<P>();
  const searchParams = new URLSearchParams(location.search);
  let params = {} as P;
  for (const key of searchParams.keys()) {
    params[key as keyof P] = searchParams.get(key) as P[keyof P];
  }
  return params;
};
export const chartFont = "sans-serif";
export const startsWithWhiteSpace = (value: string): boolean =>
  !/^\S.*[a-zA-Z\s]*$/.test(value);

export const stringContainsOnlyWhiteSpace = (value: string): boolean =>
  /^\s*$/.test(value);

export const isValidHttpUrl = (str: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );
  return pattern.test(str);
};

export const getArrayChanges = <T>(oldArray: T[] = [], newArray: T[] = []) => {
  const oldSet = new Set(oldArray);
  const newSet = new Set(newArray);
  const addedValues = Array.from(newSet).filter((value) => !oldSet.has(value));
  const deletedValues = Array.from(oldSet).filter(
    (value) => !newSet.has(value)
  );

  return {
    addedValues: addedValues.length ? addedValues : null,
    deletedValues: deletedValues.length ? deletedValues : null,
  };
};

export const formatVolume = (number?: number | null) =>
  number
    ? Intl.NumberFormat("en-US", {
        // notation: "compact",
        maximumFractionDigits: 1,
      }).format(number)
    : "-";

export const formatPrice = (number?: number | null) =>
  number ? NumberFormat(number, 2) : "-";

export const useHashRouter = () => {
  const [hash, setHash] = useHash();
  const hashName = hash === "" ? undefined : hash.replace(/#/g, "");
  return {
    hashName,
    setHash,
  };
};

export const caseInsensitiveIncludes = (
  baseString: string,
  search: string
): boolean => {
  return baseString.toUpperCase().includes(search.toUpperCase());
};

export const emissionFormatRender = (emissionValue: number): string => {
  const formatter = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });
  if (emissionValue === null) return "0";
  return formatter.format(emissionValue);
};

export const findBestMagnitude = (value: number): MagnitudeTableEmission => {
  switch (true) {
    case value >= 1_000_000_000:
      return "Gt";
    case value >= 1_000_000:
      return "Mt";
    case value >= 1_000:
      return "kt";
    default:
      return "t";
  }
};

export const sumArrayValues = (arr: number[], n: number) => {
  return arr.slice(0, n + 1).reduce((a, c) => a + c, 0);
};

export const defaultStrToInt = (
  str: string | undefined | null,
  def: number
): number => {
  if (str === undefined || str === null) return def;
  const parsedStr = parseInt(str);
  if (isNaN(parsedStr)) return def;
  return parsedStr;
};

export const generateSequence = (i: number): number[] => {
  return Array.from(Array(i).keys());
};

export const usePageNumber = (initPageNumber = 1) => {
  const [pageNumber, setPageNumber] = useState<number>(initPageNumber);
  return {
    pageNumber,
    setPageNumber,
    getNextPage: () => setPageNumber((curPageNumber) => curPageNumber + 1),
    getPreviousPage: () => setPageNumber((curPageNumber) => curPageNumber - 1),
    setToFirstPage: () => setPageNumber(1),
  };
};

export const compareObjects = <T>(oldObj: T, newObj: T) => {
  if (typeof oldObj !== "object" || typeof newObj !== "object")
    throw new Error("Both parameters must be objects");

  const changedValues = {};
  for (const [key, value] of Object.entries(newObj))
    if (oldObj[key] !== value) changedValues[key] = value;
  return changedValues;
};

export const checkIsAValidJson = (jsonStr: string): boolean => {
  try {
    JSON.parse(jsonStr);
    return true;
  } catch {
    return false;
  }
};

export const debounce = (
  debounceRef: React.MutableRefObject<NodeJS.Timeout | null>,
  callback: Function,
  delay: number
) => {
  if (debounceRef.current) {
    clearTimeout(debounceRef.current);
  }
  debounceRef.current = setTimeout(() => {
    callback();
  }, delay);
};
export const buildQuerySelect = (querySelect: Record<string, string>) =>
  Object.entries(querySelect)
    .map(([key, value]) => `${key}:${value}`)
    .join(",");

export const buildQueryJSONBSelect = (
  subSelect: string,
  querySelect: Record<string, string>
) =>
  Object.entries(querySelect)
    .map(([key, value]) => `${key}:${subSelect}->${value}`)
    .join(",");

export const sortArrayData = <T>(
  data: T[],
  sortOrder: "asc" | "desc" = "asc",
  key: string
): T[] => {
  return data.sort((a, b) => {
    const aKey = a[key];
    const bKey = b[key];

    if (typeof aKey === typeof bKey) {
      if (aKey < bKey) return sortOrder === "asc" ? -1 : 1;
      if (aKey > bKey) return sortOrder === "asc" ? 1 : -1;
      return 0;
    }

    return typeof aKey === "string" ? -1 : 1;
  });
};

export const hasEmptyValues = <T extends Record<string, any>>(
  obj?: T,
  exceptKeys?: (keyof T)[]
): boolean => {
  if (!obj) return true;
  if (Object.keys(obj).length === 0) return true;

  const keysToExclude = new Set(exceptKeys);

  return Object.entries(obj).some(([key, value]) => {
    if (keysToExclude.has(key as keyof T)) return false;

    return (
      value == null ||
      value === "" ||
      (Array.isArray(value) && !value.length) ||
      (typeof value === "object" &&
        !Array.isArray(value) &&
        hasEmptyValues(value, exceptKeys))
    );
  });
};

export const removeNullsFromObjectArrays = <T extends object>(
  obj: T
): Partial<T> => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, value.filter((item) => item !== null)];
      }
      return [key, value];
    })
  ) as Partial<T>;
};

export const pickSomeKeyObject = <T extends {}, K extends keyof T>(
  obj: T,
  ...keys: K[]
) =>
  Object.fromEntries(
    keys.filter((key) => key in obj).map((key) => [key, obj[key]])
  ) as Pick<T, K>;

export const validateIdParam = (id: string) =>
  id && id !== "0" && /^\d+$/.test(id) ? parseInt(id, 10) : null;
