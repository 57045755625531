import { useUserConfigs } from "./useUserConfig";
import ReactGridLayout from "react-grid-layout";
import { useHistory } from "react-router-dom";
import { appQueryClient } from "../state/appQueryClient";
import { coreMessage } from "../../COREDesignSystem/Feedback/COREMessage";
import { DEFAULT_HOMEPAGE_URL } from "../global";

export type Dashboard = {
  name: string;
  layout?: ReactGridLayout.Layouts | null;
};

export type DashboardOutput = {
  name: string;
  layout: ReactGridLayout.Layouts;
  removeDashboard: (redirectAfterComplete: boolean) => Promise<void>;
  updateWidgetOrName: ({
    layout,
    name,
    markAsHomepage,
  }: Dashboard & { markAsHomepage?: boolean }) => void;
};

export type DashboardConfig = {
  dashboards?: Dashboard[] | DashboardOutput[];
  defaultDashboard?: string | null;
  homepage?: string | null;
};

export type UseUserDashboardOutput = {
  sync?: boolean;
  loading?: boolean;
  error: Error | false;
  configs: DashboardConfig;
  addDashboard: (
    name: string,
    layout: ReactGridLayout.Layouts | null,
    defaultDashboard?: string
  ) => Promise<any>;
  markAsHomepage: (homepageURL: string) => Promise<any>;
};

const defaultIndexNameWidget = {
  first: "1|ChartBuilderDashboard",
  second: "2|LivePriceEnvironmental",
  third: "3|LivePriceRehub",
  fourth: "3|AemoPriceMapOverview",
};

export const defaultDashboardConfig: ReactGridLayout.Layouts = {
  lg: [
    {
      i: defaultIndexNameWidget.first,
      x: 0,
      y: 0,
      w: 6,
      h: 13,
      static: false,
    },
    {
      i: defaultIndexNameWidget.second,
      x: 0,
      y: 14,
      w: 6,
      h: 10,
      static: false,
    },
    {
      i: defaultIndexNameWidget.third,
      x: 0,
      y: 30,
      w: 6,
      h: 11,
      static: false,
    },
    {
      i: defaultIndexNameWidget.fourth,
      x: 6,
      y: 0,
      w: 6,
      h: 26,
      static: false,
    },
  ],
  md: [
    {
      i: defaultIndexNameWidget.first,
      x: 0,
      y: 0,
      w: 6,
      h: 13,
      static: false,
    },
    {
      i: defaultIndexNameWidget.second,
      x: 0,
      y: 14,
      w: 6,
      h: 10,
      static: false,
    },
    {
      i: defaultIndexNameWidget.third,
      x: 0,
      y: 30,
      w: 6,
      h: 12,
      static: false,
    },
    {
      i: defaultIndexNameWidget.fourth,
      x: 6,
      y: 0,
      w: 6,
      h: 26,
      static: false,
    },
  ],
  sm: [
    {
      i: defaultIndexNameWidget.first,
      x: 0,
      y: 0,
      w: 3,
      h: 13,
      static: false,
    },
    {
      i: defaultIndexNameWidget.second,
      x: 0,
      y: 14,
      w: 3,
      h: 10,
      static: false,
    },
    {
      i: defaultIndexNameWidget.third,
      x: 0,
      y: 30,
      w: 3,
      h: 11,
      static: false,
    },
    {
      i: defaultIndexNameWidget.fourth,
      x: 6,
      y: 0,
      w: 3,
      h: 26,
      static: false,
    },
  ],
  xs: [
    {
      i: defaultIndexNameWidget.first,
      x: 0,
      y: 0,
      w: 6,
      h: 13,
      static: false,
    },
    {
      i: defaultIndexNameWidget.second,
      x: 0,
      y: 14,
      w: 6,
      h: 10,
      static: false,
    },
    {
      i: defaultIndexNameWidget.third,
      x: 0,
      y: 30,
      w: 6,
      h: 11,
      static: false,
    },
    {
      i: defaultIndexNameWidget.fourth,
      x: 6,
      y: 0,
      w: 6,
      h: 26,
      static: false,
    },
  ],
  xxs: [
    {
      i: defaultIndexNameWidget.first,
      x: 0,
      y: 0,
      w: 6,
      h: 13,
      static: false,
    },
    {
      i: defaultIndexNameWidget.second,
      x: 0,
      y: 14,
      w: 6,
      h: 10,
      static: false,
    },
    {
      i: defaultIndexNameWidget.third,
      x: 0,
      y: 30,
      w: 6,
      h: 11,
      static: false,
    },
    {
      i: defaultIndexNameWidget.fourth,
      x: 6,
      y: 0,
      w: 6,
      h: 26,
      static: false,
    },
  ],
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useUserDashboard = (): UseUserDashboardOutput => {
  const history = useHistory();
  const { sync, loading, error, configs } =
    useUserConfigs<DashboardConfig>("dashboard"); // get dashboard data and add config for new account

  const {
    data: { config },
    updateConfig,
  } =
    configs.length === 0
      ? {
          data: {
            config: {
              dashboards: undefined,
              defaultDashboard: undefined,
              homepage: undefined,
            },
          },
          updateConfig: (newConfig: any) => Promise.resolve(newConfig),
        } // If sync be false and loading be true
      : configs[0];
  const originalConfig = config.dashboards ?? []; //TODO find new solution better then this

  const updateDashboard = (update: Array<Dashboard>) =>
    updateConfig({
      ...config,
      dashboards: update,
    }); // for update dashboard part

  const allDashboardData = originalConfig.map((data: Dashboard) => {
    // for return func that have some value already
    const filterCurrentDashboardConfig = originalConfig.filter(
      (value) => value.name !== data.name
    );
    const currentDefaultHomepage = config.homepage;

    // for remove from dashboard
    const removeDashboard = async (redirectAfterComplete: boolean) => {
      // for case the account have a dashboard
      if (originalConfig.length === 1) {
        return coreMessage({
          type: "warning",
          content: "Can not remove dashboard",
        });
      }

      const defaultDashboard =
        filterCurrentDashboardConfig[filterCurrentDashboardConfig.length - 1]
          .name;

      // For the case of removing the dashboard, which is the default homepage.
      if (currentDefaultHomepage === `/dashboard/${data.name}`) {
        await updateConfig({
          defaultDashboard: defaultDashboard,
          dashboards: filterCurrentDashboardConfig,
          homepage: DEFAULT_HOMEPAGE_URL,
        });
      } else {
        await updateDashboard(filterCurrentDashboardConfig);
      }
      await appQueryClient.invalidateQueries("userAccessibleSections");
      await appQueryClient.invalidateQueries("user_configs");
      redirectAfterComplete && history.push(`/dashboard/${defaultDashboard}`);
    };

    // for add, remove, or moving the widget or change the name of the dashboard
    const updateWidgetOrName = ({
      layout,
      name,
      markAsHomepage,
    }: Dashboard & { markAsHomepage?: boolean }) => {
      const deSelectMarkAsHomepage =
        !markAsHomepage &&
        currentDefaultHomepage === `/dashboard/${name ?? data.name}`;

      return updateConfig({
        ...config,
        ...(markAsHomepage
          ? {
              homepage: `/dashboard/${name ?? data.name}`,
            }
          : deSelectMarkAsHomepage && { homepage: DEFAULT_HOMEPAGE_URL }),
        dashboards: [
          ...filterCurrentDashboardConfig,
          {
            name: name ?? data.name,
            layout: layout ?? data.layout,
          },
        ],
      });
    };

    return {
      ...data,
      removeDashboard,
      updateWidgetOrName,
    };
  });

  const addDashboard = (
    name: string,
    layout: ReactGridLayout.Layouts | null,
    defaultDashboard?: string
  ) => {
    return updateConfig({
      ...config,
      ...(defaultDashboard && { homepage: `/dashboard/${name}` }),
      dashboards: [
        ...originalConfig,
        {
          name: name,
          layout: layout,
        },
      ],
    });
  };

  const markAsHomepage = (homepageURL: string) => {
    return updateConfig({
      ...config,
      homepage: homepageURL,
    });
  };

  return {
    sync,
    loading,
    error,
    configs: { ...config, dashboards: allDashboardData },
    addDashboard,
    markAsHomepage,
  };
};
