import React from "react";
import {
  generateTestId,
  TestID,
  TestIDWrapper,
} from "../../shared/testids/testids";
import "./COREPageHeader.less";
import { MenuItemProps, PageHeader, PageHeaderProps } from "antd";
import { Merge } from "../../shared/TypeScriptHelpers";
import { COREHeading } from "../Typography/COREHeading";
import { COREDivider } from "./COREDivider";
import { BreadcrumbItem, COREBreadcrumb } from "../Navigation/COREBreadcrumb";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useWindowSize } from "react-use";
import { SCREEN_SM_SIZE } from "../../shared/customHoooks/useBreakpoint";
import { COREButton } from "../Action/COREButton";
import { COREDropdownMenu } from "../Form/COREDropdownMenu";
import { COREMenuItem } from "../Navigation/COREMenuItem";
import { yellow100 } from "../Content/COREColour";
import { useUserDashboard } from "../../shared/customHoooks/useUserDashboard";
import { CORELoading } from "../Feedback/CORELoading";
import { useUserAccessibleSections } from "../../shared/customHoooks/useUserAccessibleSections";
import { COREEmpty } from "../Content/COREEmpty";
import { UserAccessibleSection } from "../../openapi-typescript/common/user_accessible_section";
import { matchPath, useLocation } from "react-router-dom";
import { DEFAULT_HOMEPAGE_URL } from "../../shared/global";

export type ExtraMenu =
  | {
      extra: React.ReactNode[];
      extraResponsive: {
        onClick?: () => void;
        icon?: MenuItemProps["icon"];
        children: PageHeaderProps["extra"];
        closeWhenClick?: boolean;
      }[];
    }
  | {
      extra?: never;
      extraResponsive?: never;
    };
export type COREPageHeaderProps = Merge<
  Omit<PageHeaderProps, "extra">,
  {
    testID: TestID;
    feature?: React.ReactNode;
    breadcrumbs?: BreadcrumbItem[];
  } & ExtraMenu
>;

const mappingExtraMenuWithDropdownMenu = (
  menu: Exclude<ExtraMenu["extraResponsive"], undefined>,
  testID: TestID
) =>
  menu.map(({ onClick, icon, children, closeWhenClick = false }, index) => {
    return {
      component: (
        <COREMenuItem
          testID={`${testID}-menu-item`}
          key={`${index}-default-type`}
          icon={icon}
          onClick={onClick}
        >
          {children}
        </COREMenuItem>
      ),
      closeWhenClick: closeWhenClick,
    };
  });

export const routesWithParams = [
  "/emissions-manager/initiatives/:id",
  "/emissions-manager/overview/:id",
  "/emissions-manager/offsets/:id",
];

export const checkHomePagePermission = (
  sections: UserAccessibleSection[],
  path: string
): boolean => {
  return sections.some((section) => {
    const matchesPath =
      (path && path === section.callToActionUrl) ||
      path === "/dashboard" ||
      routesWithParams.find((route) =>
        matchPath(path, { path: route, exact: true })
      );

    const hasChildrenPermission =
      section.children?.length > 0
        ? checkHomePagePermission(section.children, path)
        : false;

    return matchesPath || hasChildrenPermission;
  });
};

const useHomePage = () => {
  const location = useLocation();
  const path = decodeURIComponent(location.pathname ?? "");

  const {
    sync: syncMenu,
    loading: loadingMenu,
    error: errorMenu,
    userAccessibleSections,
  } = useUserAccessibleSections();

  const {
    sync: syncDashboard,
    loading: loadingDashboard,
    error: errorDashboard,
    configs: { homepage },
    markAsHomepage,
  } = useUserDashboard();

  const showSetupHomepage =
    path && checkHomePagePermission(userAccessibleSections, path);
  const isHomepage = homepage === path;

  return {
    sync: syncMenu && syncDashboard,
    loading: loadingMenu || loadingDashboard,
    errorMenu,
    errorDashboard,
    markCurrentURLAsHomepage: () => markAsHomepage(path),
    markAsHomepage: markAsHomepage,
    isHomepage,
    showSetupHomepage,
  };
};

export const COREPageHeader: React.FC<COREPageHeaderProps> = ({
  title,
  testID,
  extra = [],
  feature,
  extraResponsive,
  breadcrumbs,
  ...otherProps
}) => {
  const { width } = useWindowSize();
  const isSmScreen = width <= SCREEN_SM_SIZE;

  const {
    sync,
    loading,
    isHomepage,
    markCurrentURLAsHomepage,
    markAsHomepage,
    errorMenu,
    errorDashboard,
    showSetupHomepage,
  } = useHomePage();

  const onClickSetHomepage = () => {
    if (!isHomepage && showSetupHomepage) markCurrentURLAsHomepage();
    if (isHomepage && showSetupHomepage) markAsHomepage(DEFAULT_HOMEPAGE_URL);
  };

  const titleContent = (
    <div className={"title"}>
      {showSetupHomepage && isHomepage && (
        <COREIcon
          size="md"
          color={yellow100}
          icon={icon({ name: "home", style: "solid" })}
        />
      )}
      {feature}
      <COREHeading
        level={1}
        marginBottom={false}
        testID={(testID + "-title") as TestID}
      >
        {title}
      </COREHeading>
    </div>
  );

  if (loading && !sync) return <CORELoading />;

  if (errorMenu || errorDashboard) {
    console.error(errorMenu);
    console.error(errorDashboard);
    return (
      <COREEmpty
        description="Failed to load page data"
        testID={generateTestId(
          "page",
          "page-header-core-empty-error-to-load-data"
        )}
      />
    );
  }

  const responsiveHomepage = {
    onClick: onClickSetHomepage,
    icon: (
      <COREIcon
        {...(isHomepage && {
          style: { color: yellow100 },
        })}
        icon={
          isHomepage
            ? icon({
                name: "home",
                style: "solid",
              })
            : icon({
                name: "home",
                style: "regular",
              })
        }
      />
    ),
    children: "Mark as homepage",
    closeWhenClick: true,
  };

  const homepageIcon = (
    <COREButton
      key="set-default-homepage-button"
      displayTooltip={true}
      tooltipWidth="auto"
      tooltipPosition="topRight"
      tooltipTitle="Mark as homepage"
      type="default"
      testID={`${testID}-default-homepage-button`}
      size="md"
      onClick={onClickSetHomepage}
      icon={
        <COREIcon
          {...(isHomepage && { color: yellow100 })}
          icon={
            isHomepage
              ? icon({ name: "home", style: "solid" })
              : icon({ name: "home", style: "regular" })
          }
        />
      }
    />
  );

  if (isSmScreen) {
    const extraInSmScreen =
      extraResponsive && extraResponsive.length > 0
        ? [
            <COREDropdownMenu
              interactionsMenu={mappingExtraMenuWithDropdownMenu(
                [
                  ...extraResponsive,
                  ...(showSetupHomepage ? [responsiveHomepage] : []),
                ],
                testID
              )}
              isDisabled={false}
              testID={(testID + "-dropdown-menu") as TestID}
              key={"extra-menu"}
              overlayStyle={{ minWidth: "192px" }}
            >
              <COREButton
                icon={
                  <COREIcon
                    icon={icon({ name: "bars", style: "regular" })}
                    key="1"
                  />
                }
                key={"extra-menu-button"}
              />
            </COREDropdownMenu>,
          ]
        : showSetupHomepage
        ? [homepageIcon]
        : [];
    return (
      <TestIDWrapper testID={testID}>
        <div className={"core-page-header"}>
          <PageHeader
            title={titleContent}
            breadcrumbRender={() => (
              <COREBreadcrumb
                testID={"page-header-title"}
                breadcrumbs={breadcrumbs}
              />
            )}
            extra={extraInSmScreen}
            {...otherProps}
          />
          <COREDivider space={"none"} />
        </div>
      </TestIDWrapper>
    );
  }

  return (
    <TestIDWrapper testID={testID}>
      <div className={"core-page-header"}>
        <PageHeader
          title={titleContent}
          extra={[...extra, ...(showSetupHomepage ? [homepageIcon] : [])]}
          {...otherProps}
        />
        <COREDivider space={"none"} />
      </div>
    </TestIDWrapper>
  );
};
