import { Emission as EmissionAdmin } from "../../openapi-typescript/admin";
import { Emission as EmissionApproved } from "../../openapi-typescript/approved";
import { PortfolioSelection } from "./portfolioComponents/PortfolioFocusSelector";
import { Scope } from "./portfolioComponents/portfolios_helper";
import { keysToCamelCase, keysToSnake } from "../../shared/global";
import { Range } from "../../shared/date/ranges";
import { appQueryClient as queryClient } from "../../shared/state/appQueryClient";
import { Query, QueryClient } from "react-query";
import { Merge } from "../../shared/TypeScriptHelpers";
import {
  Updatables,
  useUpdatable,
  useUpdatables,
} from "../../shared/customHoooks/useUpdatable";
import { Portfolio, PortfolioPeriodScope } from "./usePortfolio";
import { WebsocketMessage } from "../../shared/state/websocket_helper";
import { QueryFilters } from "react-query/types/core/utils";
import { AxiosResponse } from "axios";
import { PortfolioPeriodScopeWSData } from "./portfolio_helper";
import { EmissionFilter } from "./portfolioComponents/createAndEdit/EmissionsTable";

type CommentWSData = Merge<
  {
    id: number;
    comment: string;
  },
  ParamsQuery
>;

type QueryString = {
  action: string;
  params: CommentWSData;
};

type ParamsQuery =
  | {
      emissionOffset: number;
    }
  | {
      initiative: number;
    };

export type Emission = Omit<EmissionAdmin | EmissionApproved, "activeRange"> & {
  portfolioPeriodScopes?: Pick<PortfolioPeriodScope, "id" | "period" | "scope">;
  activeRange?: Range;
  activeRangeText: string;
};
type EmissionWSData = Emission;

export type CreateEmission = {
  portfolio: Emission["portfolio"];
  activeRange: Emission["activeRangeText"];
  scope: PortfolioPeriodScope["scope"];
  categoryLabels?: Emission["categoryLabels"];
  emissionType: Emission["emissionType"] | null;
  quantity: Emission["quantity"] | null;
};
type EmissionProps = Partial<
  {
    categoryLabels: Emission["categoryLabels"];
    portfolio: PortfolioSelection;
    scope: Scope;
    others: Record<string, string>;
  } & Omit<EmissionFilter, "categories">
>;
const emissionUpdateHelpers = ({
  portfolio,
  scope,
  categoryLabels,
  period,
  type,
  others,
}: EmissionProps): Updatables<
  Emission,
  Emission,
  CreateEmission[],
  Emission,
  CreateEmission[]
> => ({
  get: [
    "getEmissions",
    keysToSnake({
      ...(categoryLabels &&
        categoryLabels?.length !== 0 && {
          categoryLabels:
            "eq.{" + categoryLabels.filter((label) => label).join(",") + "}",
        }),
      ...(portfolio && { portfolio: `eq.${portfolio}` }),
      ...(scope && { scope: `eq.${scope}` }),
      ...(type && keysToSnake({ emissionType: `eq.${type}` })),
      ...(period && {
        "portfolioPeriodScopes.period": `ilike.*${period}*`,
      }),
      portfolioPeriodScope: "not.is.null",
      ...others,
      order: "modified_at.desc",
      select:
        "id,portfolioPeriodScope:portfolio_period_scope,activeRange:active_range,portfolio,emissionType:emission_type,quantity,categoryLabels:category_labels,headingLabels:heading_labels,modifiedAt:modified_at,portfolioPeriodScopes:portfolio_period_scopes!inner(id,period)",
    }),
  ],
  add: (emissions: CreateEmission[]) => ({
    queryKey: [
      "createEmissions",
      {
        action: "createEmissions",
        body: JSON.stringify(
          emissions.map(
            ({ activeRange, emissionType, categoryLabels, ...e }) => {
              return {
                ...keysToSnake({
                  emissionType,
                  activeRange,
                }),
                category_labels: categoryLabels, // eslint-disable-line camelcase
                ...e,
              };
            }
          )
        ),
        enabled: true,
      },
    ],
  }),
  update: (emission: Emission) => ({
    queryKey: [
      "createEmissions",
      {
        action: "createEmissions",
        body: JSON.stringify(emission),
        enabled: true,
      },
    ],
  }),
  delete: (emission: Emission) => ({
    queryKey: [
      "deleteEmissions",
      {
        action: "deleteEmissions",
        body: JSON.stringify(emission),
      },
    ],
  }),
  transformToRichTypes: (record) => record,
});

export const useEmissions = (props: EmissionProps) =>
  useUpdatables<
    Emission,
    Emission,
    CreateEmission[],
    Emission,
    CreateEmission[]
  >(emissionUpdateHelpers(props));

export const useEmission = (props: EmissionProps) =>
  useUpdatable<
    Emission,
    Emission,
    CreateEmission[],
    Emission,
    CreateEmission[]
  >(emissionUpdateHelpers(props));

export const commonEmissionHandler = (
  data: {
    channel: string;
  },
  channelName: string,
  key: string
) => {
  if (!data.channel.startsWith(channelName)) return;
  queryClient.invalidateQueries({
    predicate: (query: Query) => {
      const queryKey = query.queryKey[0];
      return typeof queryKey === "string" && queryKey.startsWith(key);
    },
  });
};

export const commentEmissionHandler = (
  data: {
    channel: string;
    payload: {
      row: CommentWSData;
    };
  },
  channelKey: string,
  actionKey: string,
  paramKey: string
) => {
  if (!data.channel.startsWith(channelKey)) return;
  const row: CommentWSData = keysToCamelCase(data.payload.row);
  queryClient.invalidateQueries({
    predicate: (query: Query) => {
      const queryKeyTest = query.queryKey as [string, QueryString];
      return (
        queryKeyTest[1].action === actionKey &&
        queryKeyTest[1].params[paramKey as keyof CommentWSData] ===
          row[paramKey as keyof CommentWSData]
      );
    },
  });
};

let messagesToProcess: WebsocketMessage<EmissionWSData>[] = [];
let alreadyWaiting = false;
const debounceTimeout = 200;

export const emissionHandler = (
  data: WebsocketMessage<EmissionWSData>,
  queryClient: QueryClient
) => {
  if (!data.channel.startsWith("emission-company-")) return;

  messagesToProcess.push(data);
  if (alreadyWaiting) return; // already about to call no need to reschedule

  setTimeout(() => {
    emissionHandlerDebounced(messagesToProcess, queryClient);
    messagesToProcess = [];
    alreadyWaiting = false;
  }, debounceTimeout);

  alreadyWaiting = true;
};

const emissionHandlerDebounced = async (
  messages: WebsocketMessage<EmissionWSData>[],
  queryClient: QueryClient
) => {
  queryClient.invalidateQueries("getEmissions");

  const key = "portfolios";
  const queryFilter: QueryFilters = {
    predicate: (query) => (query.queryKey[0] as string).startsWith(key),
  };
  const distinctPeriodScopeID = [
    ...new Set(
      messages.map((message) => {
        const transformPayload: EmissionWSData = keysToCamelCase(
          message.payload.row
        );
        return transformPayload.portfolioPeriodScope;
      })
    ),
  ];

  distinctPeriodScopeID.forEach(async (periodScopeId) => {
    const portfolio =
      queryClient.getQueriesData<AxiosResponse<Portfolio[]>>(queryFilter);

    const portfolioPeriodScopes = portfolio.flatMap(([_key, value]) => {
      return value?.data.flatMap((p) => p.portfolioPeriodScopes);
    });
    const currentChangesPPS = portfolioPeriodScopes.find(
      (pps) => pps?.id === periodScopeId
    );

    if (currentChangesPPS?.portfolio) {
      await queryClient
        .fetchQuery<AxiosResponse<PortfolioPeriodScopeWSData[]>>({
          queryKey: [
            "portfolioPeriodScopeWithCalculation",
            {
              action: "portfolioPeriodScopeWithCalculation",
              params: {
                portfolio: `eq.${currentChangesPPS?.portfolio}`,
              },
              enabled: true,
            },
          ],
          staleTime: 0,
        })
        .then((value) => {
          const formattedPPSCalculate: PortfolioPeriodScope[] = value.data
            ? keysToCamelCase(value.data)
            : [];

          queryClient.setQueriesData(
            queryFilter,
            (current: { data: Portfolio[] } | undefined) => {
              let result: typeof current = current;
              messages.forEach(() => {
                const curData: Portfolio[] = result?.data ?? [];
                const newData: Portfolio[] = curData?.map(
                  (portfolio): Portfolio => {
                    if (portfolio.id !== currentChangesPPS?.portfolio)
                      return portfolio;
                    const newPPS = portfolio.portfolioPeriodScopes?.map(
                      (pps) => {
                        const newPPSCalculate = formattedPPSCalculate.find(
                          (d) => d.id === pps.id
                        );

                        return {
                          ...pps,
                          ...newPPSCalculate,
                        };
                      }
                    );

                    return {
                      ...portfolio,
                      portfolioPeriodScopes: newPPS,
                    };
                  }
                );
                result = {
                  ...result,
                  data: newData,
                };
              });
              return {
                ...result,
                data: result?.data ?? [],
              };
            }
          );
        });
    }
  });
};
