import React from "react";
import moment, { Moment } from "moment";
import {
  convertRelativeRangeToNonEmptyRange,
  RelativeRangeValue,
} from "../../COREDesignSystem/Form/CORERangePicker";
import {
  NonEmptyRange,
  RangeString,
  rangeToMomentDate,
  strToRange,
} from "./ranges";
import { Duration } from "moment/moment";

export type RangeFilterFormat = "YYYY-MM-DDTHH:mm:ssZ" | "YYYY-MM-DD";
export const dateFormatWithTimeZone: RangeFilterFormat = "YYYY-MM-DDTHH:mm:ssZ";
export const financeMarketTimeZone = "Australia/Sydney";
export const AEMOTimezone = "Australia/Brisbane";
export const datePickerFormat = "DD-MMM-YYYY";
export const dateAndTime = "DD-MMM-YYYY HH:mm";
export const datetimeFormatSimple = "DD-MM-YYYY";
export const datetimeFormatWithTime = "DD/MM/YYYY HH:mm:ss";
export const dateISO = "YYYY-MM-DD";
export const shortDateFormat = "DD-MMM-YY";
export const serverDateFormat = "YYYY-MM-DD";
export const csvDateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const longDateFormatWithTimeZone = "MMM D, YYYY, h:mm:ss A [GMT]Z";

export const momentToDateCSV = (moment: Moment): string => moment.toISOString();

type RangeCSVColumns = { fromDate: string; toDate: string };
export const dateRangeStrToCSVColumns = (
  dateRange: RangeString
): RangeCSVColumns => {
  const range = strToRange(dateRange);
  if (!range)
    return {
      fromDate: "-",
      toDate: "-",
    };
  const { to, from } = range;
  const { from: fromMoment, to: toMoment } = rangeToMomentDate(range);
  if (fromMoment === undefined || toMoment === undefined) {
    return {
      fromDate: "-",
      toDate: "-",
    };
  }
  if (!to.isValid() && from.isValid()) {
    return {
      fromDate: momentToDateCSV(fromMoment),
      toDate: "Ongoing",
    };
  }
  return {
    fromDate: momentToDateCSV(fromMoment),
    toDate: momentToDateCSV(toMoment),
  };
};

const defaultLocale = "en-US";

export const DateRenderer: React.FC<{
  dateString: string;
}> = ({ dateString }) => {
  return <>{moment(dateString).format(datePickerFormat)}</>;
};

export const setLocale = (locale: string) => {
  return localStorage.setItem("locale", locale);
};

const getLocale = () => {
  const locale = localStorage.getItem("locale");
  return locale !== null ? locale : defaultLocale;
};

export const formatDate = (
  date: Date,
  options: Exclude<Intl.DateTimeFormatOptions, "timeStyle"> & {
    timeStyle?: never;
  }
) => {
  const dateOptions = { ...options, timeStyle: undefined };
  return formatDateTime(date, dateOptions);
};

export const formatTime = (
  date: Date,
  options: Exclude<Intl.DateTimeFormatOptions, "dateStyle"> & {
    dateStyle?: never;
  }
) => {
  const timeOptions = { ...options, dateStyle: undefined };
  return formatDateTime(date, timeOptions);
};

export const formatDateTime = (
  date: Date,
  options: Intl.DateTimeFormatOptions
) => {
  const locale = getLocale();
  const props = {
    timeZone: financeMarketTimeZone,
    ...options,
  };
  return new Intl.DateTimeFormat(locale, props).format(date);
};

export const convertMomentToMarketTimezone = (momentTime: Moment) => {
  return moment(momentTime).clone().tz(financeMarketTimeZone);
};

export const formatRelativeRangeFilterForServer = (
  relativeRange: RelativeRangeValue,
  key: string,
  dateFormat: RangeFilterFormat
) => {
  return formatNonEmptyRangeToRangeFilter(
    convertRelativeRangeToNonEmptyRange(relativeRange),
    key,
    dateFormat
  );
};

export const formatNonEmptyRangeToRangeFilter = (
  range: NonEmptyRange,
  key: string,
  dateFormat: RangeFilterFormat
) =>
  `(${key}.gte.${convertMomentToMarketTimezone(
    range?.from.startOf("date")
  ).format(dateFormat)}, ${key}.lt.${convertMomentToMarketTimezone(
    range?.to.endOf("date")
  ).format(dateFormat)})`;

export const formatToLocalDateTimeFilter = (
  range: NonEmptyRange,
  key: string,
  dateFormat: RangeFilterFormat
) =>
  `(${key}.gte.${moment(range?.from.startOf("date"))
    .clone()
    .format(dateFormat)}, ${key}.lt.${moment(range?.to.endOf("date"))
    .clone()
    .format(dateFormat)})`;

//  this fucntion will be display relative date both of pass date and future date
//  to add unit argument, array should be order by largest to smallest like ["week","day","hour"]
export const displayRelativeDate = (
  inputDate: Moment,
  units?: Intl.RelativeTimeFormatUnit[],
  options: Intl.RelativeTimeFormatOptions = { style: "long" }
): string => {
  const now = moment();
  if (!inputDate.isValid()) return "Invalid date";
  const targetDate = moment(inputDate);
  if (targetDate.isAfter(now)) {
    const diffSeconds = targetDate.diff(now, "seconds");
    const diffMinutes = targetDate.diff(now, "minutes");
    const diffHours = targetDate.diff(now, "hours");
    const diffDays = targetDate.diff(now, "days");
    let result = "";
    if (units) {
      for (let i = 0; i < units.length; i++) {
        const unit = units[i];
        const unitEndDate = targetDate.endOf(unit);
        const diff = unitEndDate.diff(moment(), unit);

        if (diff >= 1) {
          result = unitEndDate.fromNow();
          break;
        }
      }
      if (!result) {
        result = targetDate.fromNow();
      }

      return result;
    }

    switch (true) {
      case diffSeconds < 30 && diffSeconds > 0:
        return "Now";
      case diffMinutes < 1:
        return "In 1 minute";
      case diffMinutes < 60:
        return `In ${diffMinutes} minutes`;
      case diffHours === 1:
        return "In 1 hour";
      case diffHours < 6:
        return `In ${diffHours} hours`;
      case diffHours < 24 || now.isSame(targetDate, "day"):
        return "Today";
      case diffDays === 1:
        return "Tomorrow";
      case diffDays < 7:
        return `In ${diffDays} days`;
      default:
        return targetDate.format("DD-MMM-YY");
    }
  }

  if (units) {
    const rtf = new Intl.RelativeTimeFormat("en", options);

    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      const numberOfUnits = moment().diff(targetDate, unit);
      // this is spacial case
      if (unit === "day") {
        if (numberOfUnits === 0) {
          return "Today";
        } else if (numberOfUnits === 1) {
          return "Yesterday";
        }
      }
      if (numberOfUnits >= 1) {
        return rtf.format(-numberOfUnits, unit);
      }
      if (i === units.length - 1 && numberOfUnits === 0) {
        return rtf.format(-0, unit);
      }
    }
  }

  const diffSeconds = now.diff(targetDate, "seconds");
  const diffMinutes = now.diff(targetDate, "minutes");
  const diffHours = now.diff(targetDate, "hours");
  const diffDays = now.diff(targetDate, "days");
  const diffWeeks = now.diff(targetDate, "weeks");

  switch (true) {
    case diffSeconds < 30:
      return "Just now";
    case diffMinutes < 1:
      return "A minute ago";
    case diffMinutes < 60:
      return `${diffMinutes} minutes ago`;
    case diffHours === 1:
      return "1 hour ago";
    case diffHours <= 6:
      return `${diffHours} hours ago`;
    case diffHours < 24 || now.isSame(targetDate, "day"):
      return "Today";
    case diffDays === 1:
      return "Yesterday";
    case diffDays < 7:
      return `${diffDays} days ago`;
    case diffWeeks === 1 && diffDays < 8:
      return "1 week ago";
    case diffDays > 8:
      return targetDate.format(shortDateFormat);
  }

  return targetDate.format(shortDateFormat);
};

export const durationRange: Record<string, Duration> = {
  W: moment.duration(1, "weeks"),
  M: moment.duration(1, "months"),
  Q: moment.duration(3, "months"),
  H: moment.duration(6, "months"),
  Y: moment.duration(1, "years"),
};

export const isTodayDate = (date: Moment) => {
  const today = moment().startOf("day");
  return date.isSameOrAfter(today, "day");
};

export const isYesterdayDate = (date: Moment) => {
  const today = moment().startOf("day");
  const yesterday = moment().startOf("day").subtract(1, "day");
  return date.isSameOrAfter(yesterday, "day") && date.isBefore(today, "day");
};
