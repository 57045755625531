import React from "react";
import { notification } from "antd";
import { COREButton } from "../Action/COREButton";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./CORENotification.less";
import { TestID } from "../../shared/testids/testids";
import { IconNotification, NotificationType } from "./CORENotification";
import { ArgsProps as NotificationProps } from "antd/lib/notification";
import moment from "moment";
import { dateFormatWithTimeZone } from "../../shared/date/DateTime";
import {
  SCREEN_LG_SIZE,
  SCREEN_SM_SIZE,
} from "../../shared/customHoooks/useBreakpoint";

type CORENotificationToastProps = {
  key?: string;
  titleMessage: React.ReactNode;
  descriptionMessage: React.ReactNode;
  testID: TestID;
  type?: NotificationType;
} & Omit<
  NotificationProps,
  "message" | "description" | "className" | "closeIcon" | "icon"
>;

let notificationQueue: string[] = [];

export const coreNotificationToast = ({
  key,
  titleMessage,
  descriptionMessage,
  type,
  testID,
  ...props
}: CORENotificationToastProps) => {
  const notificationKey =
    key ?? `notification-key-${moment().format(dateFormatWithTimeZone)}`;

  const getMaxCount = () => {
    const width = window.innerWidth;
    if (width <= SCREEN_SM_SIZE) return 1; // sm size
    if (width <= SCREEN_LG_SIZE) return 2; // md size
    return 3; // desktop size
  };

  const maxCount = getMaxCount();
  if (notificationQueue.length >= maxCount) {
    const excess = notificationQueue.length - maxCount + 1;
    const keysToRemove = [...notificationQueue].slice(0, excess);
    // Keep only recent notification
    notificationQueue = notificationQueue.slice(excess);
    // Close the old notification
    keysToRemove.forEach((k) => notification.close(k));
  }

  notificationQueue.push(notificationKey);

  notification.open({
    key: notificationKey,
    message: titleMessage,
    description: descriptionMessage,
    placement: "bottomRight",
    className: `notification-default-block ${type}`,
    closeIcon: (
      <COREButton
        testID={`${testID}-close-btn`}
        type="default"
        size="sm"
        icon={<COREIcon icon={icon({ name: "xmark", style: "solid" })} />}
      />
    ),
    ...(type && { icon: <IconNotification type={type} /> }),
    ...props,
  });
};
